import { UserInfoContext } from '@@/context/userInfo.context';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { CaretDownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Tooltip } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import EditCircleButton from '../EditCircleButton';
import FieldBlockInfo from '../FieldBlockInfo';
import './preview.less';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import WarningText from '@@/_new_src_/components/WarningText';
import { getCommunicationStage } from '@@/_new_src_/utils/feature/cycle';
import cycleDetailLocale from '@@/_new_src_/local/cycleDetail/en_US';

dayjs.extend(utc);

const ReviewInputPreview = ({
  reviewInputDetail,
  recentSubmittedReviewInputId,
  showEditReviewInputButton,
  onEditReviewInput,
}) => {
  const {
    aPSaveAssessment: { tooltip: aPSaveAssessmentTooltipLocale },
  } = cycleDetailLocale;

  const [isExpand, setIsExpand] = useState(false);

  const {
    currentCycleDetail: {
      isOpenCycle,
      isDraftCycle,
      twer,
      additionalPartners = [],
      performancePartner,
      isProbation,
      isCalibratingCycle,
    },
  } = useSelector(cycleDetailByVersion);

  const {
    reviewees: { reviewInputPreview: locale },
  } = useSelector(selectLocaleResource);
  const { userId } = useContext(UserInfoContext);
  const onToggleExpand = useCallback(() => {
    setIsExpand(!isExpand);
  }, [isExpand]);

  const handleEditReviewInput = useCallback(() => {
    onEditReviewInput(reviewInputDetail.id, reviewInputDetail.reviewInput);
  }, [onEditReviewInput, reviewInputDetail.id, reviewInputDetail.reviewInput]);

  const isEnableEdit = useMemo(
    () =>
      showEditReviewInputButton &&
      (isOpenCycle || isDraftCycle || isCalibratingCycle) &&
      reviewInputDetail.additionalPartnerId === userId,
    [
      isDraftCycle,
      isOpenCycle,
      showEditReviewInputButton,
      reviewInputDetail.additionalPartnerId,
      userId,
    ],
  );
  const isRemovedRole = useMemo(
    () =>
      reviewInputDetail.creatorId !== twer?.id &&
      reviewInputDetail.creatorId !== performancePartner?.id &&
      !additionalPartners.map(i => i.id).includes(reviewInputDetail.additionalPartnerId),
    [twer?.id, additionalPartners, performancePartner?.id, reviewInputDetail.additionalPartnerId],
  );

  useEffect(() => {
    if (recentSubmittedReviewInputId === reviewInputDetail.id) {
      setIsExpand(true);
    }
  }, [recentSubmittedReviewInputId, reviewInputDetail.id]);

  const currentUpdateTime = useMemo(() => {
    return dayjs.utc(reviewInputDetail.editTime).local().format('YYYY-MM-DD HH:mm:ss');
  }, [reviewInputDetail.editTime]);

  const descriptionInfo = () => (
    <>
      {locale.providedDescription[0]}
      {reviewInputDetail.additionalPartnerName}
      {isRemovedRole && <span className="removed"> {locale.removedRoleTips}</span>}
      {locale.providedDescription[1]}
      {currentUpdateTime}
    </>
  );

  const aPSubmitButtonTooltipText = () => {
    return getCommunicationStage().inStage
      ? aPSaveAssessmentTooltipLocale.inCommunicateStage
      : aPSaveAssessmentTooltipLocale.inCalibratingStage;
  };

  const operationButtonDom = () => {
    const aPEditButtonDisabled = isCalibratingCycle && !isProbation;
    return (
      <Tooltip
        title={aPEditButtonDisabled && aPSubmitButtonTooltipText}
        overlayClassName={'ap_assessment_disabled_tooltip'}
      >
        <>
          <EditCircleButton
            onClick={!aPEditButtonDisabled && handleEditReviewInput}
            disabled={aPEditButtonDisabled}
          />
        </>
      </Tooltip>
    );
  };

  return (
    <div className="contributor-review-input-preview">
      <div className="title">
        <WarningText warningText={descriptionInfo()} className="provided-time" />
        <div className="operation-icon">
          {isEnableEdit && operationButtonDom()}
          <div className="expand" onClick={onToggleExpand}>
            <CaretDownOutlined rotate={isExpand ? 180 : 0} className="expand-icon" />
          </div>
        </div>
      </div>
      <div className="content">
        <FieldBlockInfo
          isExpand={isExpand}
          collapsible
          label={locale.title}
          content={reviewInputDetail.reviewInput}
          // wrapperStyle={{ width: '986px', marginLeft: '20px' }}
        />
      </div>
    </div>
  );
};

ReviewInputPreview.defaultProps = {
  reviewInputDetail: {},
};

export default ReviewInputPreview;
