import React from 'react';

import locale from '@@/_new_src_/local/myCycles/en_US';
import { getOverdueDate } from '@@/_new_src_/utils/feature';
import './index.less';

const OverdueNotification = () => {
  const { overdueNotification: overdueNotificationLocale } = locale;

  return (
    <div className="overdue-warning-box">
      <i className="ri-error-warning-fill" />
      <div
        className={'over-text'}
        dangerouslySetInnerHTML={{ __html: overdueNotificationLocale(getOverdueDate()) }}
      />
    </div>
  );
};

export default OverdueNotification;
