import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  dateUtcTimeStamp,
  dateWithMonthDay,
  dateWithoutSeconds,
  dateWithYearMonth,
  dateWithYearMonthDay,
} from '@@/_new_src_/constants/dateFormatter';
import { get, isEmpty } from 'lodash';
import { PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';
import { IDuration } from '@@/_new_src_/store/userSlice/interface';
import { YEAR_STAGE } from '@@/_new_src_/constants/common';
import { getConfig } from '@@/common/utils';
import { IDurationStage } from '@@/_new_src_/types/features';

dayjs.extend(utc);

export const formatRangeDuration = (
  startDate?: string,
  endDate?: string,
  options?: Record<string, string>,
) => {
  const { format, separator } = {
    format: dateWithYearMonthDay,
    separator: ' - ',
    ...options,
  };
  if (!startDate && !endDate) {
    return '';
  }
  const formattedStartDate = startDate ? dayjs.utc(startDate).format(format) : '';
  const formattedEndDate = endDate ? dayjs.utc(endDate).format(format) : '';
  return `${formattedStartDate}${separator}${formattedEndDate}`;
};

export const formatTimeFun = (time: string, formatType?: string) => {
  return dayjs
    .utc(time)
    .local()
    .format(formatType || dateWithoutSeconds);
};

export const getNewDateFormatByProbation = (isProbation: boolean) =>
  isProbation ? dateWithYearMonthDay : dateWithYearMonth;

export const getDateFormatByProbation = (probationStatus: string) =>
  probationStatus === PROBATION_STATUS.UNDER_PROBATION ? dateWithYearMonthDay : dateWithYearMonth;

export const isCurrentMonth = (date: Dayjs) => {
  if (!date || !dayjs.isDayjs(date)) {
    return false;
  }
  return date.isSame(dayjs(), 'month');
};

export const getUTCFormattedTime = (date: Dayjs) => {
  if (!date || !dayjs.isDayjs(date)) {
    return null;
  }
  return date.clone().format(dateUtcTimeStamp);
};

export const getEndMonthDateTillNow = (date: Dayjs) => {
  if (!date || !dayjs.isDayjs(date)) {
    return null;
  }
  return isCurrentMonth(date)
    ? getUTCFormattedTime(dayjs().startOf('day'))
    : getUTCFormattedTime(date.endOf('month').startOf('day'));
};

export const getUTCTimestamp = (date: Dayjs) => {
  if (!date || !dayjs.isDayjs(date)) {
    return null;
  }
  return dayjs(date.clone().format(dateUtcTimeStamp)).unix();
};

export const formatRangeDateToSecond = (duration: Dayjs[]) => {
  if (isEmpty(duration)) {
    return [];
  }
  const startTime = duration[0] ? getUTCTimestamp(duration[0].startOf('month')) : undefined;
  const endTime = duration[1]
    ? getUTCTimestamp(duration[1].endOf('month').startOf('day'))
    : undefined;

  return [startTime, endTime];
};

export const formatRangeDateForProbationCycle = (startDate: Dayjs, endDate: Dayjs) => {
  const startTime = startDate ? getUTCTimestamp(startDate) : undefined;
  const endTime = endDate ? getUTCTimestamp(endDate) : undefined;
  return [startTime, endTime];
};

export const formatRangeUTCDate = (duration: Dayjs[]) => {
  if (isEmpty(duration)) {
    return [];
  }
  const startTime = duration[0] ? duration[0].utc().startOf('month').format() : undefined;
  const endTime = duration[1]
    ? duration[1].utc().endOf('month').startOf('day').format()
    : undefined;

  return [startTime, endTime];
};

export const getTimezoneOffset = () => new Date().getTimezoneOffset();

export const getTimeStamp = (date: Date) => new Date(date).getTime() / 1000;

export const currentUtcTimeInDuration = (duration: IDuration) => {
  const passTimeDurationValidation =
    getConfig().REACT_APP_IGNORE_TIME_DURATION_VALIDATION === 'true';
  const currentUTCDate = dayjs().utc();
  const startTime = dayjs.utc(duration.startTime, dateWithMonthDay).startOf('day');
  const endTime = dayjs.utc(duration.endTime, dateWithMonthDay).endOf('day');

  let isInStage = currentUTCDate.isAfter(startTime) && currentUTCDate.isBefore(endTime);
  if (startTime.isAfter(endTime)) {
    isInStage = currentUTCDate.isAfter(startTime) || currentUTCDate.isBefore(endTime);
  }
  return passTimeDurationValidation || isInStage;
};

export const getDurationStage = (stageList: IDurationStage[]) => {
  const stageInfo = stageList.find(item => {
    const { startTime, endTime } = item;
    return currentUtcTimeInDuration({ startTime, endTime });
  });
  const currentStage = get(stageInfo, 'stage', '');
  return {
    inStage: !isEmpty(stageInfo),
    inH1Stage: currentStage === YEAR_STAGE.H1,
  };
};
