import { DOCUMENT_LINK } from '@@/_new_src_/constants/pagePath';

export default {
  common: {
    readMore: 'Read more',
    readLess: 'Read less',
    emptyPlaceholder: 'N/A',
    requestToEdit: 'Request to Edit',
  },
  menu: {
    cycleManagement: 'Cycle management',
    dashboard: 'Cycles and requests',
    nonEngagedCycles: 'Non-engaged cycles',
    calibrateIndicators: 'Calibrate indicators',
    myCycles: 'My cycles',
    twersISupport: 'Twers I support',
    feedbackManage: 'Feedback management',
    admin: 'Admin',
  },
  confirmModal: {
    overdue: {
      title: 'You have overdue performance assessment to submit',
      content: (
        date: string,
      ) => `The performance cycles of one or more Thoughtworkers that you support are <b>overdue</b> now because the assessment wasn't submitted on time. <br/>
            <b>Please align the result with TWers and submit the assessment.</b> If this is not done by <b>${date}.</b> the Thoughtworkers you support will be assigned “No performance indicator”. Remember that as a Performance Partner, it is your accountability to submit performance assessment on time. <br/>
            Need support? Reach out to your <a href="${DOCUMENT_LINK.TBP_LINK}" target="_blank" style="text-decoration: underline">Talent Business Partner</a> as soon as possible.`,
      okText: 'Okay, head to the overdue cycles',
    },
    assessmentNonEngaged: {
      title: "You've been assessed as No Performance Indicator",
      content: ({
        stage,
        duration,
      }: {
        stage: string;
        duration: string;
      }) => `This happened because the last performance cycle (${duration}) has ended and your assessment wasn't submitted by your Performance Partner, thus your cycle was reported as <b>non-engaged</b> and you have been assigned <b>No Performance Indicator</b> for this cycle.</br> 
            If you have legitimate reasons for your assessment not being submitted, please reach out to your <a href="${DOCUMENT_LINK.TBP_LINK}" target="_blank" style="text-decoration: underline">Talent Business Partner</a> as soon as possible. You have until <b>${stage}</b> to request to review your performance cycle situation. 
            <p style="font-weight: 400;font-size: 16px;font-style: italic">Notes: The system will consider this message read if you don’t click the button below until ${stage}. </p>`,
      okText: 'Okay, got it',
    },
    expectationNonEngaged: {
      title: 'Your cycle status changed from Drafted to Opened',
      firstContent: `Your cycle has been <b>automatically opened </b>because the expectations were not set up and published by `,
      secondContent: `, and the system filled in a standardized grade expectation on your behalf to facilitate the ongoing performance process. <br><br>Please <b>update the expectations with your personal context ASAP</b> so your cycle will be more meaningful to your growth and performance journey. You'll be assessed based on the standardized expectations if you keep them until the assessment phase.`,
      okText: 'Okay, got it',
    },
  },
  tag: {
    assessmentNonEngagedTag: 'Assessment non-engaged',
    nonEngagedCycleByTBPTag: 'Cycle opened by TBP',
  },
  uploadExcel: {
    import: 'Import list',
    operationTip: `Click or drag <b>a single Excel file</b> to this area to upload`,
  },
};
