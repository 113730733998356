import StatusTips from '@@/_new_src_/features/StatusTips';
import React, { useEffect, useMemo, useState } from 'react';
import cycleDetailLocale from '@@/_new_src_/local/cycleDetail/en_US';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequestData, resetSendRequestData } from '@@/_new_src_/store/cycleDetailSlice';
import { getRequestToEditStatus } from '@@/_new_src_/utils/feature/cycle';
import {
  getRequestToEditByCycleIdAsync,
  postRequestToEditAsync,
} from '@@/_new_src_/store/cycleDetailSlice/asyncThunk';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import cls from 'classnames';
import ReasonInput from '@@/_new_src_/components/ReasonInput';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import { setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import { useHistory } from 'react-router-dom';
import { CURRENT_PAGE, CYCLE_PAGE_LINK, MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { formatTimeFun } from '@@/_new_src_/utils/common/date';
import { getRequestToEditTips } from '@@/_new_src_/utils/feature';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import './index.less';

const SendRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isNonEngagedCyclesPage } = CURRENT_PAGE(location.pathname);

  const {
    requestToEdit: {
      title: titleLocale,
      confirmModalProps: confirmModalPropsLocale,
      sendRequest: sendRequestLocale,
      sendRequest: { statusWords: statusWordsLocale, statusTips: statusTipsLocale },
    },
  } = cycleDetailLocale;

  const {
    currentCycleDetail: {
      id: cycleId,
      cycleVersion,
      isTwer,
      isPerformancePartner,
      assessDate,
      canRequestToEdit,
      isDoneCycle,
      isUnreadCycle,
      isFirstHalfOfYear,
    },
  } = useSelector(cycleDetailByVersion);

  const {
    status,
    requestedAt,
    rejectReason,
    rejectedAt,
    approvedAt,
    isSubmitRequestLoading,
    isGetRequestLoading,
    isGetRequestSuccess,
    isSubmitRequestSuccess,
  } = useSelector(sendRequestData);

  const [isEditing, setIsEditing] = useState(false);
  const [reason, setReason] = useState('');

  useEffect(() => {
    cycleId && dispatch(getRequestToEditByCycleIdAsync(cycleId));
  }, [cycleId]);

  useEffect(() => {
    isSubmitRequestSuccess && cycleId && dispatch(getRequestToEditByCycleIdAsync(cycleId));
  }, [isSubmitRequestSuccess, cycleId]);

  useEffect(() => {
    return () => {
      dispatch(resetSendRequestData());
    };
  }, []);

  const {
    isOpenRequestToEdit,
    isApprovedRequestToEdit,
    isRejectedRequestToEdit,
    isCompletedOrRejectedRequestToEdit,
  } = getRequestToEditStatus(status);

  const getStatusWords = () => {
    const currentOpenWords = isOpenRequestToEdit && statusWordsLocale.openStatus;

    const currentApprovedWords = isOpenRequestToEdit && statusWordsLocale.approvedStatus;

    const currentCompletedOrRejectedWords =
      isCompletedOrRejectedRequestToEdit &&
      ((isTwer &&
        getRequestToEditTips(
          isFirstHalfOfYear,
          statusWordsLocale.completedOrRejectedStatusForTwerFirst,
          statusWordsLocale.completedOrRejectedStatusForTwerSecond,
          statusWordsLocale.completedOrRejectedStatusForTwerThird,
        )) ||
        (isPerformancePartner &&
          getRequestToEditTips(
            isFirstHalfOfYear,
            statusWordsLocale.completedOrRejectedStatusForPPFirst,
            statusWordsLocale.completedOrRejectedStatusForPPSecond,
            statusWordsLocale.completedOrRejectedStatusForPPThird,
          )));

    return currentOpenWords || currentApprovedWords || currentCompletedOrRejectedWords || '';
  };

  const getCurrentStatusTips = () => {
    const currentOpenTips =
      isOpenRequestToEdit && statusTipsLocale.openTips(formatTimeFun(requestedAt));

    const currentApprovedTips =
      isApprovedRequestToEdit &&
      getRequestToEditTips(
        isFirstHalfOfYear,
        statusTipsLocale.approvedTipsFirst(formatTimeFun(requestedAt), formatTimeFun(approvedAt)),
        statusTipsLocale.approvedTipsSecond,
      );

    const currentRejectedTips =
      isRejectedRequestToEdit &&
      statusTipsLocale.rejectedTips(
        formatTimeFun(requestedAt),
        formatTimeFun(rejectedAt),
        rejectReason,
      );

    return currentOpenTips || currentApprovedTips || currentRejectedTips || '';
  };

  const submitEditRightsRequestFun = () => {
    !isEditing && setIsEditing(true);
  };

  const setReasonFun = (reason: string) => {
    setReason(reason);
  };

  const submitRequestFun = () => {
    !isSubmitRequestLoading &&
      cycleId &&
      dispatch(postRequestToEditAsync({ cycleId: cycleId, reason: reason }));
  };

  const handleConfirmCancelFunc = () => {
    dispatch(setConfirmModalStatus(false));
  };

  const handleConfirmOkFunc = () => {
    isTwer
      ? history.push(CYCLE_PAGE_LINK.MY_CYCLES)
      : history.push(MENU_PAGE_LINK.TWERS_I_SUPPORT_PAGE);
    dispatch(setConfirmModalStatus(false));
    setReason('');
    isEditing && setIsEditing(false);
  };

  const confirmModalProps = () => {
    const { title, content, cancelText, okText } = confirmModalPropsLocale;
    return {
      title: title,
      content: content,
      cancelText: cancelText,
      okText: okText,
      handleOkFunc: handleConfirmOkFunc,
      handleCancelFunc: handleConfirmCancelFunc,
    };
  };

  const showSendRequest = useMemo(() => {
    const sendVersion = cycleVersion === CYCLE_VERSION.NEW;
    const sendPageStatus = !isGetRequestLoading && isGetRequestSuccess;
    const sendControlStatus = canRequestToEdit && assessDate;
    const sendRole = isTwer || isPerformancePartner;
    const sendCycleStatus = isDoneCycle || isUnreadCycle;
    const sendPageRoute = !isNonEngagedCyclesPage;

    return (
      sendVersion &&
      sendPageStatus &&
      sendControlStatus &&
      sendRole &&
      sendCycleStatus &&
      sendPageRoute
    );
  }, [
    cycleVersion,
    isGetRequestLoading,
    isGetRequestSuccess,
    canRequestToEdit,
    assessDate,
    isTwer,
    isPerformancePartner,
    isDoneCycle,
    isUnreadCycle,
    isNonEngagedCyclesPage,
  ]);

  return (
    showSendRequest && (
      <div className={'deal-request-wrapper'}>
        <h3 className={'title'}>{titleLocale}</h3>
        <div className={'send-request-detail'}>
          <StatusTips
            status={status}
            statusTips={getCurrentStatusTips()}
            statusWords={getStatusWords()}
          />
          {isCompletedOrRejectedRequestToEdit && (
            <div className={'operate'}>
              <button
                className={cls('edit-btn', {
                  'edit-btn-disabled': isEditing,
                })}
                disabled={isEditing}
                onClick={submitEditRightsRequestFun}
              >
                <i className="ri-edit-fill" />
                <span className={'edit-text'}>{sendRequestLocale.submitEditRequestText}</span>
              </button>
            </div>
          )}
          {isCompletedOrRejectedRequestToEdit && isEditing && (
            <ReasonInput
              label={sendRequestLocale.label}
              placeholder={sendRequestLocale.placeholder}
              onChangeReason={setReasonFun}
              confirmReasonFun={submitRequestFun}
              confirmBtnText={sendRequestLocale.submitBtn}
            />
          )}
          {isEditing && <ConfirmModal {...confirmModalProps()} />}
        </div>
      </div>
    )
  );
};

export default SendRequest;
