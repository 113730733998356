import RouteChange from '@@/common/component/RouteChange';
import React, { ReactNode, useEffect } from 'react';
import { getPPListAsync, getUserInfoAsync } from '@@/_new_src_/store/userSlice/asyncThunk';
import { useDispatch, useSelector } from 'react-redux';
import { REVIEWEE_SORT_FIELD } from '@@/common/constant/reviewee';
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { CURRENT_PAGE, MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import locale from '@@/_new_src_/local/common/en_US';

import { getTabTitle } from '@@/common/utils';
import { userStore } from '@@/_new_src_/store/userSlice';
import './Layout.less';
import { overdueAndNonEngagedTip, setCycleVersion } from '@@/_new_src_/store/myCyclesSlice';
import { UserInfoContext } from '@@/context/userInfo.context';
import Footer from '@@/common/component/Footer';
import { ProductUpdateModalContainer } from '@@/features/performance/modal/ProductUpdateModalContainer';
import { withOktaAuth } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { App, ConfigProvider } from 'antd';
import HeaderMenu from '@@/_new_src_/features/HeaderMenu';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackComponent from '@@/_new_src_/components/FallbackComponent';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import {
  confirmModalType,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import {
  getOverdueAndNonEngagedTipAsync,
  postAssessmentNonEngagedTipAsync,
  postConfirmExpectationNonEngagedAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import TokenExpireModal from '@@/_new_src_/features/TokenExpireModal';
import {
  getHomePageUrl,
  isFirstLogin,
  setUserInfo,
  setUserLoggedIn,
} from '@@/_new_src_/utils/common/auth';
import {
  getAssessmentNonEngagedDate,
  getExpectationNonEngagedTips,
  getOverdueDate,
} from '@@/_new_src_/utils/feature';
import { setResetNonEngagedCycles } from '@@/_new_src_/store/nonEngagedCyclesSlice';

interface IProps extends RouteComponentProps {
  oktaAuth: OktaAuth;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  authState: any;
  children: ReactNode;
}

const Layout = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const { children } = props;

  const {
    userInfo,
    userInfo: { success: isGetUserInfoSuccess, userId, currentCycleVersion = CYCLE_VERSION.OLD },
  } = useSelector(userStore);

  const {
    latestAssessmentNonEngagedCycleId,
    hasAssessmentNonEngagedCycleTip,
    assessmentNonEngagedCycleDuration,
    expectationNonEngagedCycleId,
    hasExpectationNonEngagedNotConfirmCycle,
    expectationNonEngagedAt,
  } = useSelector(overdueAndNonEngagedTip);

  const currentConfirmModalType = useSelector(confirmModalType);

  const { isIndexPage, isNonEngagedCyclesPage } = CURRENT_PAGE(pathname);

  useEffect(() => {
    !isNonEngagedCyclesPage && dispatch(setResetNonEngagedCycles());
  }, [pathname]);

  const winScroll = () => {
    const sy = window.scrollX;
    document.getElementsByClassName('performance-layout-header')[0]?.scrollTo(sy, 0);
  };

  const getBasicInfo = async () => {
    await dispatch(getPPListAsync({ sortColumnName: REVIEWEE_SORT_FIELD.DEFAULT }));
    await dispatch(getOverdueAndNonEngagedTipAsync());
    await dispatch(getUserInfoAsync());
  };

  useEffect(() => {
    window.addEventListener('scroll', winScroll);

    if (isFirstLogin() && isIndexPage) {
      history.replace(MENU_PAGE_LINK.LANDING_PAGE);
    }
    getBasicInfo();
    window.addEventListener('beforeunload', () => {
      setUserLoggedIn();
    });
    return () => {
      setUserLoggedIn();
    };
  }, []);

  useEffect(() => {
    if (hasExpectationNonEngagedNotConfirmCycle) {
      dispatch(setConfirmModalStatus(true));
      dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.EXPECTATION_NON_ENGAGED_CYCLE));
      return;
    }
    if (userInfo.hasOverdueCycleAsPerformancePartner) {
      dispatch(setConfirmModalStatus(true));
      dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.OVERDUE_CYCLE));
    }
  }, [userInfo, hasExpectationNonEngagedNotConfirmCycle]);

  useEffect(() => {
    if (hasAssessmentNonEngagedCycleTip) {
      dispatch(setConfirmModalStatus(true));
      dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.ASSESSMENT_NON_ENGAGED_CYCLE));
    }
  }, [hasAssessmentNonEngagedCycleTip]);

  useEffect(() => {
    const needLoad = localStorage.getItem('authorityChange');
    const pathname = history.location.pathname;
    getTabTitle(pathname);

    if (needLoad === 'true') {
      getBasicInfo();
      localStorage.setItem('authorityChange', 'false');
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (isGetUserInfoSuccess) {
      dispatch(setCycleVersion(currentCycleVersion));
      setUserInfo(userInfo);
      if (isIndexPage) {
        history.replace(getHomePageUrl(userInfo));
      }
    }
  }, [isGetUserInfoSuccess]);

  const confirmExpectationNonEngagedAsync = async () => {
    dispatch(postConfirmExpectationNonEngagedAsync(expectationNonEngagedCycleId));
  };

  const handleConfirmOkFunc = (type?: string) => {
    if (type === CONFIRM_MODAL_TYPE_INFO.OVERDUE_CYCLE) {
      history.push('/performance/twerisupport');
    }
    if (type === CONFIRM_MODAL_TYPE_INFO.ASSESSMENT_NON_ENGAGED_CYCLE) {
      dispatch(postAssessmentNonEngagedTipAsync(latestAssessmentNonEngagedCycleId));
    }
    if (type === CONFIRM_MODAL_TYPE_INFO.EXPECTATION_NON_ENGAGED_CYCLE) {
      confirmExpectationNonEngagedAsync().then(() => {
        if (userInfo.hasOverdueCycleAsPerformancePartner) {
          dispatch(setConfirmModalStatus(true));
          dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.OVERDUE_CYCLE));
        }
      });
    }
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalType(''));
  };

  const overdueConfirmModalProps = () => {
    const { title, content, okText } = locale.confirmModal.overdue;
    const getOverDate = getOverdueDate();

    return {
      title: title,
      content: content(getOverDate),
      okText: okText,
      handleOkFunc: () => {
        handleConfirmOkFunc(CONFIRM_MODAL_TYPE_INFO.OVERDUE_CYCLE);
      },
      closeIcon: null,
      modalClassName: 'overdue-modal',
      maskClosable: false,
      cancelText: '',
    };
  };

  const assessmentNonEngagedConfirmModalProps = () => {
    const { title, content, okText } = locale.confirmModal.assessmentNonEngaged;

    return {
      title: title,
      content: content(getAssessmentNonEngagedDate(assessmentNonEngagedCycleDuration)),
      okText: okText,
      handleOkFunc: () => {
        handleConfirmOkFunc(CONFIRM_MODAL_TYPE_INFO.ASSESSMENT_NON_ENGAGED_CYCLE);
      },
      closeIcon: null,
      modalClassName: 'non-engaged-modal',
      maskClosable: false,
      cancelText: '',
    };
  };

  const expectationNonEngagedConfirmModalProps = () => {
    const { title, firstContent, secondContent, okText } =
      locale.confirmModal.expectationNonEngaged;
    const content = getExpectationNonEngagedTips(
      expectationNonEngagedAt,
      firstContent,
      secondContent,
    );

    return {
      title: title,
      content: content,
      okText: okText,
      handleOkFunc: () => {
        handleConfirmOkFunc(CONFIRM_MODAL_TYPE_INFO.EXPECTATION_NON_ENGAGED_CYCLE);
      },
      closeIcon: null,
      modalClassName: 'expectation-non-engaged-modal',
      maskClosable: false,
      cancelText: '',
    };
  };

  return (
    <RouteChange>
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        {userId && (
          <ConfigProvider theme={{ token: { fontFamily: 'Open Sans' } }}>
            <App className={'antd-app-component'}>
              <div className="performance-layout">
                <HeaderMenu />
                <main className="performance-layout-content">
                  <UserInfoContext.Provider
                    value={{
                      ...userInfo,
                      updateUserInfo: () => {
                        dispatch(getUserInfoAsync());
                      },
                    }}
                  >
                    <div className="performance-page-container">{children}</div>
                    {currentConfirmModalType ===
                      CONFIRM_MODAL_TYPE_INFO.EXPECTATION_NON_ENGAGED_CYCLE && (
                      <ConfirmModal {...expectationNonEngagedConfirmModalProps()} />
                    )}
                    {currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.OVERDUE_CYCLE && (
                      <ConfirmModal {...overdueConfirmModalProps()} />
                    )}
                    {currentConfirmModalType ===
                      CONFIRM_MODAL_TYPE_INFO.ASSESSMENT_NON_ENGAGED_CYCLE && (
                      <ConfirmModal {...assessmentNonEngagedConfirmModalProps()} />
                    )}
                  </UserInfoContext.Provider>
                </main>
                <Footer />
                <ProductUpdateModalContainer userInfo={userInfo} />
                <TokenExpireModal />
              </div>
            </App>
          </ConfigProvider>
        )}
      </ErrorBoundary>
    </RouteChange>
  );
};
export default withOktaAuth(withRouter(Layout));
