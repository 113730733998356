import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import './index.less';
import { Modal, Tooltip } from 'antd';
import { IConfirmModalProps } from '@@/_new_src_/types/common';
import {
  confirmModalLoading,
  confirmModalStatus,
  setConfirmModalLoading,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import { isString } from 'lodash';

const ConfirmModal = (popupProps: IConfirmModalProps) => {
  const {
    title,
    content,
    okText,
    cancelText,
    width = 900,
    handleOkFunc,
    handleCancelFunc,
    modalClassName,
    confirmIconClassName,
    closeIcon = <i className="ri-close-line" style={{ width: 40 }} />,
    maskClosable = true,
    okButtonDisabled = false,
    okButtonDisabledToolTip = '',
  } = popupProps;
  const dispatch = useDispatch();
  const currentVisible = useSelector(confirmModalStatus);
  const loading = useSelector(confirmModalLoading);

  const closeModal = () => {
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalLoading(false));
    dispatch(setConfirmModalType(''));
  };

  const handleOk = () => {
    if (handleOkFunc) {
      handleOkFunc();
    }
    closeModal();
  };

  const handleCancel = () => {
    if (handleCancelFunc) {
      handleCancelFunc();
      return;
    }
    closeModal();
  };

  return (
    <>
      {currentVisible && (
        <Modal
          className={cls('confirm-modal', modalClassName)}
          centered={true}
          title={title}
          open={currentVisible}
          confirmLoading={loading}
          width={width}
          maskClosable={maskClosable}
          closeIcon={closeIcon}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <Tooltip
                title={okButtonDisabledToolTip}
                placement="bottom"
                overlayStyle={{ maxWidth: '500px' }}
              >
                <>
                  <OkBtn />
                </>
              </Tooltip>
            </>
          )}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ disabled: okButtonDisabled }}
          okText={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <i
                className={cls(
                  confirmIconClassName ? confirmIconClassName : 'ri-checkbox-circle-fill',
                  'confirm-modal-btn',
                )}
              />
              {okText}
            </div>
          }
          cancelText={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <i className="ri-close-circle-fill confirm-modal-btn" />
              {cancelText}
            </div>
          }
        >
          {isString(content) ? (
            <div dangerouslySetInnerHTML={{ __html: content }} />
          ) : (
            <>{content}</>
          )}
        </Modal>
      )}
    </>
  );
};

export default ConfirmModal;
