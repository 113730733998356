import { readRequestRejected, updateReviewByReviewer } from '@@/api/review';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import RequestRejectedTips from '@@/common/component/RequestRejectedTips';
import ReviewDetailPreview, {
  DEFAULT_OPERATION_CONFIG,
} from '@@/common/component/ReviewDetailPreview';
import ReviewInputTab from '@@/common/component/ReviewInputTab';
import { AP_CHECK_CYCLE, PP_CHECK_CYCLE, WRITE_ASSESSMENT } from '@@/common/constant/qualaroo';
import { GET_CYCLE_DETAIL_API_PATH, LIMIT_PARTNER_NUM } from '@@/common/constant/review';
import { TABS_KEY } from '@@/common/constant/reviewee';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { RevieweeTableFilterContext } from '@@/context/revieweeTableFilter.context';
import usePerfModal from '@@/hooks/usePerfModal';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import {
  selectActiveTab,
  selectAdditionalTalentInformation,
  selectCurrentReviewInputId,
  selectIsCheckedGC,
  selectIsCheckedHP,
  selectIsCheckedNoTalent,
  selectIsEditRatingSummary,
  selectIsEditReviewInput,
  selectReviewInput,
  selectSelectedRating,
  selectSummaryState,
  setActiveTab,
  setCurrentReviewInputId,
  setIsEditRatingSummary,
  setIsEditReviewInput,
  setReviewInput,
} from '@@/redux/slice/revieweeSlice';
import { get, isEmpty } from 'lodash';
import { Button, Checkbox, Tooltip } from 'antd';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import LimitMultiPartnerModal from './LimitMultiPartnerModal';
import SubmitAssessmentModal from './SubmitAssessmentModal';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import CycleDetailByIdLayout from '@@/features/performance/reviews/cycleDetailByIdLayout';
import { CYCLE_STATUS_ENUM, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { getCycleByCycleIdAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { getPerformancePartnerCycleDetailByIdAsync } from '@@/redux/slice/reviewSlice';
import { MY_CYCLE_DETAIL_API, TWER_I_SUPPORT_BY_PP_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  ppSaveAssessment,
  sendRequestData,
  setPpSaveAssessment,
} from '@@/_new_src_/store/cycleDetailSlice';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import dayjs from 'dayjs';
import { getUTCTimestamp } from '@@/_new_src_/utils/common/date';
import { getCommunicationStage, getPpAssessmentStage } from '@@/_new_src_/utils/feature/cycle';
import {
  CALIBRATION_COMMUNICATION_DURATION,
  CONFIRM_MODAL_TYPE_INFO,
  PP_ADD_ASSESSMENT_DATE,
} from '@@/_new_src_/constants/common';
import { putPPSaveAssessmentAsync } from '@@/_new_src_/store/cycleDetailSlice/asyncThunk';
import { PP_SAVE_ASSESSMENT_VALUE } from '@@/_new_src_/constants/CycleStatus';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import cycleDetailLocale from '@@/_new_src_/local/cycleDetail/en_US';
import {
  confirmModalType,
  setConfirmModalLoading,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import { MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { userInfo } from '@@/_new_src_/store/userSlice';
import './index.less';

const CheckboxGroup = Checkbox.Group;

export default function RevieweeDetail() {
  const activeTab = useSelector(selectActiveTab);
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const { id: cycleIdByPathname } = useParams();

  const {
    pPSaveAssessment: {
      confirmModal: pPSaveAssessmentModalLocale,
      confirmModal: { checkList: checkListLocale },
    },
    aPSaveAssessment: { tooltip: aPSaveAssessmentTooltipLocale },
    doneCalibrationCommunication: { confirmModal: ppDoneCalibrationCommunicationModalLocale },
  } = cycleDetailLocale;

  const { reviewees: revieweesLocale, reviewHistory } = useSelector(selectLocaleResource);
  const { detail: locale, multiPartnerModalContent: modalLocale } = revieweesLocale;

  const { userId } = useSelector(userInfo);

  const {
    currentCycleDetail: {
      id: cycleId,
      cycleVersion,
      cycleDetailLoading,
      editRequestRejected,
      additionalPartnerAssessments = [],
      assessment,
      additionalPartners,
      twer,
      isOpenCycle,
      isDoneCycle,
      isDraftCycle,
      isOpenOrDraftCycle,
      isClosedCycle,
      isCalibratingCycle,
      isTwer,
      isAdditionalPartners,
      isPerformancePartner,
      duration: { endTime = '' } = {},
      isProbation,
      createBySystem,
      isFirstHalfOfYear,
    } = {},
  } = useSelector(cycleDetailByVersion);

  const { isGetRequestLoading: isGetRequestToEditLoading } = useSelector(sendRequestData);

  const isEditRatingSummary = useSelector(selectIsEditRatingSummary);
  const currentReviewInputId = useSelector(selectCurrentReviewInputId);
  const selectedRating = useSelector(selectSelectedRating);
  const summaryState = useSelector(selectSummaryState);
  const isCheckedHP = useSelector(selectIsCheckedHP);
  const isCheckedGC = useSelector(selectIsCheckedGC);
  const isCheckedNoTalent = useSelector(selectIsCheckedNoTalent);
  const additionalTalentInformation = useSelector(selectAdditionalTalentInformation);

  const reviewInput = useSelector(selectReviewInput);
  const isEditReviewInput = useSelector(selectIsEditReviewInput);
  const { success: ppSaveAssessmentSuccess, operationType } = useSelector(ppSaveAssessment);
  const currentConfirmModalType = useSelector(confirmModalType);

  const { updateIsFromDetailPageInitialed } = useContext(RevieweeTableFilterContext);

  const [reviewInputFromBackend, setReviewInputFromBackend] = useState('');
  const [recentSubmittedReviewInputId, setRecentSubmittedReviewInputId] = useState('');
  const [reviewDetailNeedActivatedTab, setReviewDetailNeedActivatedTab] = useState(
    TABS_KEY.REVIEW_DETAIL,
  );
  const [submitAssessmentLoading, setSubmitAssessmentLoading] = useState(false);

  const [checkedList, setCheckedList] = useState([]);

  const [
    isSubmitAssessmentModalVisible,
    onOpenSubmitAssessmentModal,
    onCloseSubmitAssessmentModal,
  ] = usePerfModal();

  const getCurrentDetailFunc = useCallback(() => {
    if (cycleVersion === CYCLE_VERSION.NEW) {
      cycleIdByPathname && dispatch(getCycleByCycleIdAsync(+cycleIdByPathname));
    } else {
      cycleIdByPathname && dispatch(getPerformancePartnerCycleDetailByIdAsync(+cycleIdByPathname));
    }
  }, [cycleVersion, cycleIdByPathname]);

  const onBackToList = () => {
    history.push(MENU_PAGE_LINK.TWERS_I_SUPPORT_PAGE, { isFromDetailPage: true });
  };

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    updateIsFromDetailPageInitialed(true);
  }, [updateIsFromDetailPageInitialed]);

  const editable = useMemo(
    () => !cycleDetailLoading && !isDoneCycle,
    [isDoneCycle, cycleDetailLoading],
  );

  const onEditRatingSummary = useCallback(() => {
    scrollToTop();
    dispatch(setIsEditRatingSummary(true));
    dispatch(setActiveTab(TABS_KEY.REVIEW_DETAIL));
    history.replace(
      `/performance/twerisupport/${cycleIdByPathname}?activeTab=${TABS_KEY.REVIEW_DETAIL}`,
    );
  }, [dispatch, scrollToTop]);

  const onCancelEditRatingSummary = () => {
    dispatch(setIsEditRatingSummary(false));
    scrollToTop();
  };
  const onEditReviewInput = useCallback(
    (reviewInputId, reviewInputContent) => {
      dispatch(setIsEditReviewInput(true));
      dispatch(setCurrentReviewInputId(reviewInputId));
      dispatch(setReviewInput(reviewInputContent));
      setReviewInputFromBackend(reviewInputContent);
    },
    [dispatch],
  );

  const submitAssessmentSuccess = useCallback(async () => {
    isPerformancePartner &&
      trackEvent({
        category: TWER_I_SUPPORT_BY_PP_API.category,
        action: TWER_I_SUPPORT_BY_PP_API.action.PP_SUBMITS_ASSESSMENT_SUMMARY,
      });
    perfMessage.success(locale.submitAssessmentModal.successMessage);
    onBackToList();
    trigSurvey(WRITE_ASSESSMENT);
  }, [
    locale.submitAssessmentModal.successMessage,
    onBackToList,
    assessment,
    cycleIdByPathname,
    selectedRating,
    summaryState,
  ]);

  const submitAssessmentV1 = useCallback(async () => {
    setSubmitAssessmentLoading(true);
    await updateReviewByReviewer(cycleIdByPathname, {
      rating: selectedRating || assessment.rating,
      summary: summaryState?.trim() || assessment.summary,
      additionalTalentInformation: !isEmpty(additionalTalentInformation)
        ? additionalTalentInformation
        : assessment.additionalTalentInformation,
      targetStatus: CYCLE_STATUS_ENUM.UNREAD,
      // current time is timestamp with 8h after
      currentTime: getUTCTimestamp(dayjs()),
    }).finally(() => setSubmitAssessmentLoading(false));
    submitAssessmentSuccess().then();
  }, [additionalTalentInformation, assessment, cycleIdByPathname, selectedRating, summaryState]);

  const onProvideReviewInput = useCallback(() => {
    history.replace(
      `/performance/twerisupport/${cycleIdByPathname}?activeTab=${TABS_KEY.REVIEW_INPUT}`,
    );
    dispatch(setIsEditReviewInput(true));
    setReviewDetailNeedActivatedTab(TABS_KEY.REVIEW_INPUT);
    dispatch(setActiveTab(TABS_KEY.REVIEW_INPUT));
    dispatch(setCurrentReviewInputId(''));
  }, [dispatch, history, cycleIdByPathname]);

  const pPCanEditAssessment = useMemo(() => {
    if (isProbation) {
      const endDate = dayjs.utc(new Date(endTime));
      const currentDate = dayjs.utc(new Date());
      return currentDate >= endDate;
    }

    if (createBySystem) {
      return (
        (isOpenCycle || isDraftCycle) && getPpAssessmentStage().inH1Stage === isFirstHalfOfYear
      );
    }
    return true;
  }, [isProbation, endTime, createBySystem, isOpenCycle, isDraftCycle, isFirstHalfOfYear]);

  const isDisabledSubmitForPp = useMemo(() => {
    if (!pPCanEditAssessment) {
      return true;
    }

    if (!isEditRatingSummary && isEmpty(assessment)) {
      return true;
    }
    if (
      !isEditRatingSummary &&
      assessment.rating &&
      assessment.summary &&
      assessment.additionalTalentInformation
    ) {
      return false;
    }
    return !(
      isEditRatingSummary &&
      selectedRating &&
      summaryState?.trim() &&
      (isCheckedGC || isCheckedHP || isCheckedNoTalent)
    );
  }, [
    assessment,
    isCheckedGC,
    isCheckedHP,
    isCheckedNoTalent,
    isEditRatingSummary,
    selectedRating,
    summaryState,
    pPCanEditAssessment,
  ]);

  const isProvidedReviewInput = useMemo(() => {
    return additionalPartnerAssessments
      ?.map(({ additionalPartnerId }) => additionalPartnerId)
      .includes(userId);
  }, [additionalPartnerAssessments, userId]);

  const onSaveRatingSummary = () => {
    scrollToTop();
    dispatch(setIsEditRatingSummary(false));
    getCurrentDetailFunc();
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onSubmitClick = useCallback(() => {
    if (additionalPartners?.length > LIMIT_PARTNER_NUM) {
      setIsModalVisible(true);
      return;
    }
    if (createBySystem && !isProbation) {
      dispatch(setConfirmModalStatus(true));
      if (getCommunicationStage().inH1Stage === isFirstHalfOfYear) {
        dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.PP_DONE_COMMUNICATION));
        return;
      }
      if (getPpAssessmentStage().inH1Stage === isFirstHalfOfYear) {
        dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.PP_SUBMIT_ASSESSMENT));
      }
    } else {
      onOpenSubmitAssessmentModal();
    }
  }, [additionalPartners, onOpenSubmitAssessmentModal, isFirstHalfOfYear]);

  const pPSubmitButtonDisabledTooltipText = useMemo(() => {
    if (isProbation) {
      return locale.probationCannotSubmitTooltip;
    } else if (!createBySystem) {
      return '';
    } else {
      if (isCalibratingCycle) {
        return locale.cannotSubmitToolTipInCalibrating;
      }
      const startAssessmentDate = isFirstHalfOfYear
        ? PP_ADD_ASSESSMENT_DATE.H1
        : PP_ADD_ASSESSMENT_DATE.H2;
      return locale.cannotSubmitTooltip(startAssessmentDate);
    }
  }, [endTime, isProbation]);

  const aPSubmitButtonTooltipText = () => {
    return getCommunicationStage().inStage
      ? aPSaveAssessmentTooltipLocale.inCommunicateStage
      : aPSaveAssessmentTooltipLocale.inCalibratingStage;
  };

  const operationButtonDom = ({ tooltipTitle, onClickFun, buttonDisabled, buttonText }) => {
    return (
      <Tooltip title={tooltipTitle}>
        <Button
          type="primary"
          onClick={onClickFun}
          disabled={buttonDisabled}
          className="reviewee-detail-header-button"
        >
          {buttonText}
        </Button>
      </Tooltip>
    );
  };

  const renderOperationButton = useCallback(() => {
    const isPpUpdateAssessment =
      isEditRatingSummary &&
      isPerformancePartner &&
      isCalibratingCycle &&
      getCommunicationStage().inStage;

    if (isClosedCycle || isPpUpdateAssessment) {
      return null;
    }

    if (isAdditionalPartners && !isEditReviewInput && !isProvidedReviewInput) {
      const aPSubmitButtonDisabled = isCalibratingCycle && !isProbation;
      return operationButtonDom({
        tooltipTitle: aPSubmitButtonDisabled && aPSubmitButtonTooltipText(),
        onClickFun: !aPSubmitButtonDisabled && onProvideReviewInput,
        buttonDisabled: aPSubmitButtonDisabled,
        buttonText: locale.provideReviewInput,
      });
    }

    const submitButtonTextForPp =
      createBySystem && !isProbation ? locale.submitNowToCalibrating : locale.submitNow;

    if (!isAdditionalPartners) {
      if (isEditRatingSummary || isDraftCycle || isCalibratingCycle) {
        const operationButtonInfo = {
          communication: {
            tooltipTitle: locale.doneCommunicationToolTip(
              isFirstHalfOfYear
                ? CALIBRATION_COMMUNICATION_DURATION.H1.endDate
                : CALIBRATION_COMMUNICATION_DURATION.H2.endDate,
            ),
            onClickFun: onSubmitClick,
            buttonDisabled: false,
            buttonText: locale.doneCommunication,
          },
          calibrating: {
            tooltipTitle: isDisabledSubmitForPp && pPSubmitButtonDisabledTooltipText,
            onClickFun: !isDisabledSubmitForPp && onSubmitClick,
            buttonDisabled: isDisabledSubmitForPp,
            buttonText: submitButtonTextForPp,
          },
        };

        const getOperationButtonInfo =
          getCommunicationStage().inStage && isCalibratingCycle
            ? operationButtonInfo.communication
            : operationButtonInfo.calibrating;

        return operationButtonDom({ ...getOperationButtonInfo });
      }
      return (
        <Button
          type="primary"
          onClick={onEditRatingSummary}
          className="reviewee-detail-header-button"
        >
          {locale.assessNow}
        </Button>
      );
    }
    return null;
  }, [
    isClosedCycle,
    isAdditionalPartners,
    isEditReviewInput,
    isProvidedReviewInput,
    onProvideReviewInput,
    locale.provideReviewInput,
    locale.assessNow,
    locale.submitNow,
    isEditRatingSummary,
    isDraftCycle,
    onEditRatingSummary,
    onSubmitClick,
    isDisabledSubmitForPp,
    pPSubmitButtonDisabledTooltipText,
    isProbation,
    pPCanEditAssessment,
    isOpenOrDraftCycle,
  ]);

  const onReadRequestRejected = useCallback(
    id => () => {
      isTwer &&
        trackEvent({
          category: MY_CYCLE_DETAIL_API.category,
          action:
            MY_CYCLE_DETAIL_API.action
              .TWER_READS_REJECT_TO_CYCLE_EDITING_REQUEST_IN_THE_CYCLE_DETAIL_PAGE,
        });
      isPerformancePartner &&
        trackEvent({
          category: TWER_I_SUPPORT_BY_PP_API.category,
          action: TWER_I_SUPPORT_BY_PP_API.action.PP_READS_REJECT_TO_CYCLE_IN_CYCLE_DETAIL,
        });
      readRequestRejected(id).then();
    },
    [],
  );

  const onCancelReviewInput = useCallback(() => {
    if (isEmpty(additionalPartnerAssessments)) {
      dispatch(setActiveTab(TABS_KEY.REVIEW_DETAIL));
    }
    dispatch(setIsEditReviewInput(false));
    dispatch(setReviewInput(''));
    setReviewInputFromBackend('');
    dispatch(setCurrentReviewInputId(''));
    scrollToTop();
  }, [scrollToTop, additionalPartnerAssessments, dispatch]);

  const onSubmitReviewInput = useCallback(
    async submittedReviewInputId => {
      await getCurrentDetailFunc();
      scrollToTop();
      setRecentSubmittedReviewInputId(submittedReviewInputId);
      dispatch(setIsEditReviewInput(false));
      dispatch(setReviewInput(''));
      setReviewInputFromBackend('');
      dispatch(setCurrentReviewInputId(''));
    },
    [getCurrentDetailFunc, scrollToTop, dispatch],
  );

  const renderExtra = useCallback(() => {
    if (editRequestRejected && isDoneCycle) {
      return (
        <RequestRejectedTips
          message={reviewHistory.requestRejectedTips}
          onClose={onReadRequestRejected(cycleId)}
        />
      );
    }
    return null;
  }, [
    isDoneCycle,
    onReadRequestRejected,
    editRequestRejected,
    cycleId,
    reviewHistory.requestRejectedTips,
  ]);

  const renderReviewInputTabContent = useCallback(() => {
    if (
      !isEmpty(additionalPartnerAssessments) ||
      activeTab === TABS_KEY.REVIEW_INPUT ||
      isEditReviewInput
    ) {
      return (
        <ReviewInputTab
          isProvideReviewInput={
            editable && isAdditionalPartners && isEditReviewInput && !currentReviewInputId
          }
          reviewInputEditorProps={{
            reviewInput,
            currentReviewInputId,
            onReviewInputChange: value => dispatch(setReviewInput(value)),
            onCancelReviewInput,
            onSubmitReviewInput,
            onEditReviewInput,
            recentSubmittedReviewInputId,
            reviewInputFromBackend,
          }}
        />
      );
    }
    return null;
  }, [
    activeTab,
    currentReviewInputId,
    editable,
    isAdditionalPartners,
    isEditReviewInput,
    onCancelReviewInput,
    onEditReviewInput,
    onSubmitReviewInput,
    recentSubmittedReviewInputId,
    reviewInput,
    reviewInputFromBackend,
    dispatch,
  ]);

  useEffect(() => {
    cycleVersion === CYCLE_VERSION.NEW &&
      isOpenCycle &&
      isAdditionalPartners &&
      trigSurvey(AP_CHECK_CYCLE);
    cycleVersion === CYCLE_VERSION.NEW &&
      isOpenCycle &&
      isPerformancePartner &&
      trigSurvey(PP_CHECK_CYCLE);
  }, [cycleVersion, isAdditionalPartners, isPerformancePartner, isOpenCycle]);

  const isDetailLoading = useMemo(() => {
    if (cycleVersion === CYCLE_VERSION.OLD) {
      return cycleDetailLoading;
    } else {
      return cycleDetailLoading || isGetRequestToEditLoading;
    }
  }, [cycleDetailLoading, isGetRequestToEditLoading]);

  const handleConfirmOkFunc = useCallback(() => {
    dispatch(
      putPPSaveAssessmentAsync({
        cycleId,
        summary: summaryState?.trim() || assessment.summary,
        additionalTalentInformation: !isEmpty(additionalTalentInformation)
          ? additionalTalentInformation
          : assessment.additionalTalentInformation,
        rating: selectedRating || assessment.rating,
        operationType: getCommunicationStage().inStage
          ? PP_SAVE_ASSESSMENT_VALUE.DONE_COMMUNICATING
          : PP_SAVE_ASSESSMENT_VALUE.SUBMIT,
      }),
    );
  }, [additionalTalentInformation, assessment, cycleIdByPathname, selectedRating, summaryState]);

  const handleCancelSubmitAssessmentFunc = () => {
    setCheckedList([]);
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalLoading(false));
    dispatch(setConfirmModalType(''));
  };

  const onCheckSubmitAssessment = list => {
    setCheckedList(list);
  };

  const renderSubmitAssessmentModalContent = () => {
    const { content } = pPSaveAssessmentModalLocale;
    return (
      <div className="submit-assessment-confirm-modal">
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
        <CheckboxGroup
          options={checkListLocale}
          value={checkedList}
          onChange={onCheckSubmitAssessment}
        />
      </div>
    );
  };

  const isSubmitAssessmentModalOkButtonDisabled = useMemo(() => {
    return checkedList.length !== checkListLocale.length;
  }, [checkedList]);

  const renderDoneCalibrationCommunicationModalContent = () => {
    const {
      content: { p1, p2 },
    } = ppDoneCalibrationCommunicationModalLocale;
    return (
      <div className="done-communication-modal-content">
        <div className="content">
          <p>{p1}</p>
          <p>{p2}</p>
        </div>
      </div>
    );
  };

  const submitAssessmentConfirmModalProps = () => {
    const { title, cancelText, okText, okToolTip } = pPSaveAssessmentModalLocale;

    return {
      title: title,
      modalClassName: 'submit-assessment-confirm-modal',
      content: renderSubmitAssessmentModalContent(),
      cancelText: cancelText,
      okText: okText,
      handleOkFunc: handleConfirmOkFunc,
      handleCancelFunc: handleCancelSubmitAssessmentFunc,
      okButtonDisabled: isSubmitAssessmentModalOkButtonDisabled,
      okButtonDisabledToolTip: isSubmitAssessmentModalOkButtonDisabled ? okToolTip : '',
    };
  };

  const ppDoneCalibrationCommunicationModalProps = () => {
    const { title, cancelText, okText } = ppDoneCalibrationCommunicationModalLocale;

    return {
      title: title,
      content: renderDoneCalibrationCommunicationModalContent(),
      cancelText: cancelText,
      okText: okText,
      handleOkFunc: handleConfirmOkFunc,
    };
  };

  useEffect(() => {
    if (operationType === PP_SAVE_ASSESSMENT_VALUE.SUBMIT && ppSaveAssessmentSuccess) {
      submitAssessmentSuccess().then();
      dispatch(setPpSaveAssessment({ success: false }));
      setCheckedList([]);
    }
    if (operationType === PP_SAVE_ASSESSMENT_VALUE.DONE_COMMUNICATING && ppSaveAssessmentSuccess) {
      cycleIdByPathname && dispatch(getCycleByCycleIdAsync(+cycleIdByPathname));
    }
  }, [ppSaveAssessmentSuccess]);

  return (
    <CycleDetailByIdLayout v1ApiPath={GET_CYCLE_DETAIL_API_PATH.isReviewer}>
      <div className="reviewee-detail">
        <GoBackBanner
          title={get(twer, 'name', '')}
          goToBackFun={onBackToList}
          onKeyPress={onBackToList}
          children={editable && renderOperationButton()}
        />
        <div className="reviewee-detail-content">
          <LoadingWrapper loading={isDetailLoading}>
            <ReviewDetailPreview
              isRevieweeReviewDetail
              showFeedbackTabThoughNoSubmittedFeedback={false}
              defaultExpand
              ratingSummaryEditable={isEditRatingSummary}
              onSaveRatingSummary={onSaveRatingSummary}
              onCancelEditRatingSummary={onCancelEditRatingSummary}
              onEditRatingSummary={onEditRatingSummary}
              onSendEditRequestSuccess={getCurrentDetailFunc}
              onCheckInNotesModalSuccess={getCurrentDetailFunc}
              onRevieweeReviewEditCancel={scrollToTop}
              needActivatedTab={reviewDetailNeedActivatedTab}
              extra={renderExtra()}
              operation={{ ...DEFAULT_OPERATION_CONFIG }}
              reviewInputTab={renderReviewInputTabContent()}
              isSubmitModalVisible={isSubmitAssessmentModalVisible}
            />
          </LoadingWrapper>
        </div>
        {createBySystem && !isProbation ? (
          currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.PP_SUBMIT_ASSESSMENT && (
            <ConfirmModal {...submitAssessmentConfirmModalProps()} />
          )
        ) : (
          <SubmitAssessmentModal
            visible={isSubmitAssessmentModalVisible}
            onCancel={onCloseSubmitAssessmentModal}
            onClickSubmit={createBySystem ? handleConfirmOkFunc : submitAssessmentV1}
            loading={submitAssessmentLoading}
          />
        )}
        {createBySystem &&
          !isProbation &&
          currentConfirmModalType === CONFIRM_MODAL_TYPE_INFO.PP_DONE_COMMUNICATION && (
            <ConfirmModal {...ppDoneCalibrationCommunicationModalProps()} />
          )}

        <LimitMultiPartnerModal
          isModalVisible={isModalVisible}
          modalLocale={modalLocale}
          onCloseClick={() => setIsModalVisible(false)}
          onClickConfirm={() => setIsModalVisible(false)}
        />
      </div>
    </CycleDetailByIdLayout>
  );
}
