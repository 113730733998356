import { get, isEmpty, isInteger, isNull } from 'lodash';
import { isEmptyOrNil } from '@@/_new_src_/utils';
import {
  IAdditionalPartnerAssessment,
  ICycleInfo,
  IExpectationItem,
  IPersonInfo,
} from '@@/_new_src_/store/cycleDetailSlice/interface';
import { CYCLE_STATUS_ENUM, CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { REQUEST_TO_EDIT_STATUS } from '@@/_new_src_/constants/requestToEditStatus';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';
import {
  IContributorReviewInputs,
  ICurCycleDetailData,
  ICycleDetailByVersionProps,
  ICycleDetailProps,
  ICycleInfoV1,
  ICycleOwnerRoleProps,
  IExpectation,
} from '@@/_new_src_/types/cycle';
import { getDurationStage } from '@@/_new_src_/utils/common/date';
import { YEAR_STAGE } from '@@/_new_src_/constants/common';

export const getNewAdditionalPartnerAssessments = (
  contributorReviewInputs: IContributorReviewInputs[],
) => {
  if (!isEmptyOrNil(contributorReviewInputs)) {
    return contributorReviewInputs.map(item => {
      return {
        id: item.id,
        reviewInput: item.reviewInput,
        additionalPartnerId: item.creatorId,
        additionalPartnerName: item.creatorName,
        editTime: item.editedTime,
      };
    });
  } else {
    return [];
  }
};

export const getCurrentCycleDetailData = ({
  cycleVersion,
  cycleDetailByVersion1,
  cycleDetailByVersion2,
}: ICycleDetailProps) => {
  const cycleDetailV1 = get(cycleDetailByVersion1, 'data', {}) as ICycleInfoV1;
  const cycleDetailV2 = get(cycleDetailByVersion2, 'data', {}) as ICycleInfo;
  const currentCycleData =
    cycleVersion === CYCLE_VERSION.NEW
      ? cycleDetailV2
      : (cycleDetailV1 as ICycleInfo | ICycleInfoV1);

  let cycleInfo: ICurCycleDetailData = {
    cycleDetailLoading:
      cycleVersion === CYCLE_VERSION.NEW
        ? get(cycleDetailByVersion2, 'loading', false)
        : get(cycleDetailByVersion1, 'loading', false),
    cycleVersion,
    id: get(currentCycleData, 'id', null),
    status: get(currentCycleData, 'status', ''),
    duration: get(currentCycleData, 'duration', {}),
    assessment: get(currentCycleData, 'assessment', null),
    canRequestToEdit: get(currentCycleData, 'canRequestToEdit', false),
    canEdit: get(currentCycleData, 'canEdit', false),
    feedbacks: get(currentCycleData, 'feedbacks', []),
    checkInNotes: get(currentCycleData, 'checkInNotes', ''),
    createdTime: get(currentCycleData, 'createdTime', null),
    openBy: get(currentCycleData, 'openBy', ''),
    additionalPartnerInputs: get(currentCycleData, 'additionalPartnerInputs', ''),

    isNoneEngaged: get(cycleDetailV2, 'isNoneEngaged', false),
    context: get(cycleDetailV2, 'context', {
      content: '',
      attachmentName: '',
      attachmentStorageUrl: '',
    }),
    twer: get(cycleDetailV2, 'twer', { id: null, name: '' }),
    expectations: get(cycleDetailV2, 'expectations', []) as IExpectation | IExpectationItem[],
    performancePartner: get(cycleDetailV2, 'performancePartner', { id: null, name: '' }),
    additionalPartners: get(cycleDetailV2, 'additionalPartners', []),
    project: get(cycleDetailV2, 'project', { name: '', assignmentId: '', account: '' }),
    selfAssessment: get(cycleDetailV2, 'selfAssessment', null),
    isProbation: get(cycleDetailV2, 'isProbation', false),
    additionalPartnerAssessments: get(cycleDetailV2, 'additionalPartnerAssessments', []),
    isEditRequestRejected: get(cycleDetailV2, 'isEditRequestRejected', false),
    isAssessmentUpdated: get(cycleDetailV2, 'isAssessmentUpdated', false),
  };

  if (cycleVersion !== CYCLE_VERSION.NEW) {
    cycleInfo = {
      ...cycleInfo,
      isNoneEngaged: get(cycleDetailV1, 'noneEngaged', false),
      twer: get(cycleDetailV1, 'reviewee', { id: null, name: '' }),
      expectations: get(cycleDetailV1, 'expectation', {
        text: '',
        attachmentName: '',
        attachmentUrl: '',
        attachmentExcelLink: '',
      }),
      performancePartner: get(cycleDetailV1, 'reviewers.reviewer', {
        id: null,
        name: '',
      }) as IPersonInfo,
      additionalPartners: get(cycleDetailV1, 'reviewers.contributors', []) as IPersonInfo[],
      project: {
        name: get(cycleDetailV1, 'project.projectName', '') as string,
        assignmentId: get(cycleDetailV1, 'project.assignmentId', '') as string,
        account: get(cycleDetailV1, 'project.accountId', '') as string,
      },
      selfAssessment: get(cycleDetailV1, 'selfReview.id')
        ? {
            id: get(cycleDetailV1, 'selfReview.id', null) as number,
            content: get(cycleDetailV1, 'selfReview.content', '') as string,
            attachmentName: get(cycleDetailV1, 'selfReview.attachmentName', '') as string,
            attachmentStorageUrl: get(cycleDetailV1, 'selfReview.attachmentUrl', '') as string,
          }
        : null,
      isProbation: get(cycleDetailV1, 'probationStatus') === PROBATION_STATUS.UNDER_PROBATION,
      additionalPartnerAssessments: getNewAdditionalPartnerAssessments(
        get(cycleDetailV1, 'contributorReviewInputs', []),
      ) as IAdditionalPartnerAssessment[] | [],
      isEditRequestRejected: get(cycleDetailV1, 'editRequestRejected', false) as boolean,
      isAssessmentUpdated: get(currentCycleData, 'assessmentUpdated', false) as boolean,
    };
  }

  return cycleInfo;
};

export const getCycleOwnerRole = ({
  userId,
  cycleInfo: { performancePartner, additionalPartners = [], twer },
}: ICycleOwnerRoleProps) => {
  return {
    isPerformancePartner: get(performancePartner, 'id') === userId || false,
    isAdditionalPartners: additionalPartners.some(item => {
      return item.id === userId;
    }),
    isTwer: get(twer, 'id') === userId || false,
  };
};

export const getCycleStatus = (
  cycleStatus = '',
  isOverdue = false,
  assessmentNonEngaged = false,
) => {
  const { NEW, OPEN, DONE, CLOSED, CANCELLED, DRAFT, UNREAD, CALIBRATING } = CYCLE_STATUS_ENUM;
  const isNewCycle = cycleStatus === NEW || false;
  const isOpenCycle = cycleStatus === OPEN || false;
  const isCancelledCycle = cycleStatus === CANCELLED || false;
  const isUnreadCycle = cycleStatus === UNREAD || false;
  const isDraftCycle = cycleStatus === DRAFT || false;
  const isDoneCycle = cycleStatus === UNREAD || cycleStatus === DONE || false;
  const isClosedCycle = cycleStatus === CLOSED || false;
  const isOpenOrDraftCycle =
    ((cycleStatus === OPEN || cycleStatus === DRAFT) && !isOverdue) || false;
  const isOverdueCycle = ((cycleStatus === OPEN || cycleStatus === DRAFT) && isOverdue) || false;
  const isAssessmentNonEngagedCycle =
    ((cycleStatus === DONE || cycleStatus === UNREAD) && assessmentNonEngaged) || false;
  const isCalibratingCycle = cycleStatus === CALIBRATING || false;

  return {
    isNewCycle,
    isOpenCycle,
    isCancelledCycle,
    isUnreadCycle,
    isDoneCycle,
    isDraftCycle,
    isClosedCycle,
    isOpenOrDraftCycle,
    isOverdueCycle,
    isAssessmentNonEngagedCycle,
    isCalibratingCycle,
  };
};

export const getCycleDetailByVersion = ({
  userId,
  cycleVersion,
  cycleDetailByVersion1,
  cycleDetailByVersion2,
}: ICycleDetailByVersionProps) => {
  const cycleInfo = getCurrentCycleDetailData({
    cycleVersion,
    cycleDetailByVersion1,
    cycleDetailByVersion2,
  });

  const { isPerformancePartner, isAdditionalPartners, isTwer } = getCycleOwnerRole({
    userId,
    cycleInfo,
  });

  const {
    isNewCycle,
    isOpenCycle,
    isCancelledCycle,
    isUnreadCycle,
    isDoneCycle,
    isDraftCycle,
    isClosedCycle,
    isOpenOrDraftCycle,
  } = getCycleStatus(cycleInfo?.status);

  return {
    ...cycleInfo,
    isPerformancePartner,
    isAdditionalPartners,
    isTwer,
    isNewCycle,
    isOpenCycle,
    isCancelledCycle,
    isUnreadCycle,
    isDoneCycle,
    isDraftCycle,
    isClosedCycle,
    isOpenOrDraftCycle,
  };
};

export const cycleStatusTag = ({
  isOverdueCycle,
  status,
}: {
  status: string;
  isOverdueCycle: boolean;
}) => {
  const { isOpenOrDraftCycle } = getCycleStatus(status);
  if (isOpenOrDraftCycle && isOverdueCycle) {
    return CYCLE_STATUS_ENUM.OVERDUE;
  }
  return status;
};

export const goToCycleFormPage = ({
  cycleVersion,
  id,
}: {
  cycleVersion: number | null;
  id?: number | null;
}) => {
  let search = '';
  const currentId = id?.toString() || '';
  if (!isEmpty(currentId)) {
    search = '/' + currentId;
  }
  return cycleVersion === CYCLE_VERSION.NEW
    ? CYCLE_PAGE_LINK.NEW_CYCLES_FORM + search
    : CYCLE_PAGE_LINK.OLD_CYCLES_FORM + search;
};

export const goToCycleDetailPage = ({
  currentOpeningCycleId,
  cycleId = NaN,
}: {
  currentOpeningCycleId: number | null;
  cycleId: number | null;
}) => {
  if (isNull(cycleId) || !isInteger(+cycleId) || cycleId === currentOpeningCycleId)
    return CYCLE_PAGE_LINK.MY_CYCLES;

  return CYCLE_PAGE_LINK.CYCLE_HISTORY_DETAIL + '/' + cycleId;
};

export const getRequestToEditStatus = (status: string) => {
  const isOpenRequestToEdit = status === REQUEST_TO_EDIT_STATUS.OPEN;
  const isApprovedRequestToEdit = status === REQUEST_TO_EDIT_STATUS.APPROVED;
  const isRejectedRequestToEdit = status === REQUEST_TO_EDIT_STATUS.REJECTED;
  const isCompletedRequestToEdit = status === REQUEST_TO_EDIT_STATUS.COMPLETED || status === '';
  const isCompletedOrRejectedRequestToEdit = isCompletedRequestToEdit || isRejectedRequestToEdit;
  const isCompletedOrRejectedOrOpenRequestToEdit =
    isCompletedRequestToEdit || isRejectedRequestToEdit || isOpenRequestToEdit;
  return {
    isOpenRequestToEdit,
    isApprovedRequestToEdit,
    isRejectedRequestToEdit,
    isCompletedRequestToEdit,
    isCompletedOrRejectedRequestToEdit,
    isCompletedOrRejectedOrOpenRequestToEdit,
  };
};

const ppAssessmentStage = [
  { startTime: '05-15', endTime: '06-20', stage: YEAR_STAGE.H1 },
  { startTime: '11-15', endTime: '12-20', stage: YEAR_STAGE.H2 },
];

const communicationStage = [
  { startTime: '07-27', endTime: '08-10', stage: YEAR_STAGE.H1 },
  { startTime: '01-27', endTime: '02-10', stage: YEAR_STAGE.H2 },
];

const tbpCalibrationStage = [
  { startTime: '06-21', endTime: '08-17', stage: YEAR_STAGE.H1 },
  { startTime: '12-21', endTime: '02-17', stage: YEAR_STAGE.H2 },
];

export const getPpAssessmentStage = () => {
  return getDurationStage(ppAssessmentStage);
};

export const getCommunicationStage = () => {
  return getDurationStage(communicationStage);
};

export const getTBPCalibrateStage = () => {
  return getDurationStage(tbpCalibrationStage);
};
