import { CYCLE_DISPLAY_PART } from '@@/_new_src_/constants/myCycles';
import {
  deleteExpectationAsync,
  getAttachmentsUrlAsync,
  getCycleByCycleIdAsync,
  getCycleFeedbacksAsync,
  getOngoingCycleAsync,
  getOverdueAndNonEngagedTipAsync,
  getPastCycleAvailableYearAsync,
  getPastCyclesByYearAsync,
  postAddExpectationAsync,
  postAddInformationAsync,
  postAssessmentNonEngagedTipAsync,
  postCancelCycleAsync,
  postOpenNormalCycleAsync,
  postUploadAttachmentAsync,
  putUpdateExpectationAsync,
} from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import {
  IAttachment,
  ICycleFeedbacks,
  IMyCycle,
  IPastCycle,
  IPastYear,
  IState,
} from '@@/_new_src_/store/myCyclesSlice/interface';
import { omit } from '@okta/okta-auth-js';
import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'lodash';
import { putUpdateInformationAsync } from './asyncThunk';
import { AttachmentType } from '@@/_new_src_/constants/attachmentType';
import { RequestFeedbackListOptions } from '@@/_new_src_/constants/feedback';
import { INITIAL_ASYNC_STATUS } from '@@/_new_src_/constants/common';
import { IAsyncStatus } from '@@/_new_src_/types/common';
import { filterRichText } from '@@/_new_src_/utils';
import {
  ICycleDetailData,
  ICycleInfo,
  IExpectationItem,
  IInformation,
} from '@@/_new_src_/store/cycleDetailSlice/interface';

export const initialState: IMyCycle = {
  // todo
  cycleDisplayPart: CYCLE_DISPLAY_PART.DEFAULT_DISPLAY,
  cycleVersion: null,
  expectationList: [],
  isOperationCycle: false,
  cycleInformation: {} as IInformation,
  currentCycleId: null,
  currentCycleStatus: '',
  attachment: {
    ...INITIAL_ASYNC_STATUS,
    attachmentUrl: '',
    filename: '',
    storageName: '',
    url: '',
  },
  editDoneCycleChanged: false,
  selfAssessmentAttachment: {
    ...INITIAL_ASYNC_STATUS,
    attachmentUrl: '',
    filename: '',
    storageName: '',
    url: '',
  },
  v1ExpectationAttachment: {
    ...INITIAL_ASYNC_STATUS,
    attachmentUrl: '',
    filename: '',
    storageName: '',
    url: '',
  },
  cycleFeedbacks: {
    ...INITIAL_ASYNC_STATUS,
    list: [],
  },
  cycleInformationFormData: null,
  helperModalOptions: 'noHelperDisplay',
  cycleDetailData: {
    loading: false,
    data: {} as ICycleInfo,
  } as ICycleDetailData,
  currentUpdatedExpectation: null,
  lastExpectationCategoryIdsAfterEditing: null,
  isUpdateInformationSuccess: INITIAL_ASYNC_STATUS,
  cancelStatus: INITIAL_ASYNC_STATUS,
  isUpdatingExpectation: false,
  isPostOpenSuccess: false,
  deleteExpectationStatus: INITIAL_ASYNC_STATUS,
  pastCyclesStore: {
    pastCycles: [],
    getPastCyclesLoading: false,
    getPastCyclesSuccess: false,
  },
  pastYearStore: {
    pastYear: {
      userId: NaN,
      reviewHistoryYear: [],
    },
    getPastYearLoading: false,
    getPastYearSuccess: false,
  },
  pastCyclesPageStore: {
    cycleFilterValue: RequestFeedbackListOptions[0].value,
    selectedCycle: '0',
    selectedYear: '',
  },
  overdueAndNonEngagedTip: {
    latestAssessmentNonEngagedCycleId: NaN,
    hasAssessmentNonEngagedCycle: false,
    hasAssessmentNonEngagedCycleTip: false,
    hasAssessmentNonEngagedCycleAsPP: false,
    assessmentNonEngagedCycleDuration: { startTime: '', endTime: '' },
    showCompletedTips: false,
    showExpectationNonEngagedCycleTips: false,
    expectationNonEngagedCycleId: NaN,
    hasExpectationNonEngagedNotConfirmCycle: false,
    expectationNonEngagedAt: '',
  },
  updateAssessmentNonEngagedTip: INITIAL_ASYNC_STATUS,
};
const myCyclesSlice = createSlice({
  name: 'myCycles',
  initialState: { ...initialState },
  reducers: {
    setCycleDisplayPart: (state, action) => {
      state.cycleDisplayPart = action.payload as string;
    },
    setCycleVersion: (state, action) => {
      state.cycleVersion = action.payload as number;
    },
    setCycleInformation: (state, action) => {
      const {
        context,
        context: { content = '' },
      } = action.payload;

      state.cycleInformation = {
        ...action.payload,
        context: {
          ...context,
          content: filterRichText(content),
        },
      } as IInformation;
    },
    setExpectationList: (state, action) => {
      state.expectationList = undefined ? [] : (action.payload as IExpectationItem[]);
    },
    setIsOperationCycle: (state, action) => {
      state.isOperationCycle = action.payload as boolean;
    },
    setCurrentCycleId: (state, action) => {
      state.currentCycleId = action.payload as number;
    },
    setCurrentCycleStatus: (state, action) => {
      state.currentCycleStatus = action.payload as string;
    },
    setCycleInformationFormData: (state, action) => {
      state.cycleInformationFormData = action.payload as IInformation;
    },
    setEditDoneCycleChanged: (state, action) => {
      state.editDoneCycleChanged = action.payload as boolean;
    },
    setAttachment: (state, action) => {
      const {
        type,
        data,
        data: { isInitialValue = false } = {},
      } = action.payload as {
        type: string;
        data: IAttachment;
      };

      switch (type) {
        case AttachmentType.CYCLE_INFORMATION:
          isInitialValue
            ? (state.attachment = { ...initialState.attachment })
            : (state.attachment = {
                ...state.attachment,
                ...data,
              } as IAttachment);
          break;
        case AttachmentType.SELF_ASSESSMENT:
          isInitialValue
            ? (state.selfAssessmentAttachment = { ...initialState.selfAssessmentAttachment })
            : (state.selfAssessmentAttachment = {
                ...state.selfAssessmentAttachment,
                ...data,
              } as IAttachment);
          break;
        case AttachmentType.V1_CYCLE_EXPECTATION:
          isInitialValue
            ? (state.v1ExpectationAttachment = { ...initialState.v1ExpectationAttachment })
            : (state.v1ExpectationAttachment = {
                ...state.v1ExpectationAttachment,
                ...data,
              } as IAttachment);
          break;
        default:
      }
    },
    setSelfAssessmentAttachment: (state, action) => {
      const payload = action.payload as IAttachment;
      if (payload?.isInitialValue) {
        state.selfAssessmentAttachment = { ...initialState.selfAssessmentAttachment };
      } else {
        state.selfAssessmentAttachment = {
          ...state.selfAssessmentAttachment,
          ...action.payload,
        } as IAttachment;
      }
    },
    setV1ExpectationAttachment: (state, action) => {
      const payload = action.payload as IAttachment;
      if (payload?.isInitialValue) {
        state.v1ExpectationAttachment = { ...initialState.v1ExpectationAttachment };
      } else {
        state.v1ExpectationAttachment = {
          ...state.v1ExpectationAttachment,
          ...action.payload,
        } as IAttachment;
      }
    },
    setHelperModalOptions: (state, action) => {
      state.helperModalOptions = action.payload as string;
    },
    setCycleDetailData: (state, action) => {
      const currentCycleDetailData = isEmpty(action.payload)
        ? initialState.cycleDetailData
        : state.cycleDetailData;

      state.cycleDetailData = {
        ...currentCycleDetailData,
        ...action.payload,
      } as ICycleDetailData;
    },
    setCurrentUpdatedExpectation: (state, action) => {
      state.currentUpdatedExpectation = action.payload as IExpectationItem;
    },
    setLastExpectationCategoryIdsAfterEditing: (state, action) => {
      state.lastExpectationCategoryIdsAfterEditing = action.payload as number[];
    },
    setIsUpdateInformationSuccess: (state, action) => {
      state.isUpdateInformationSuccess = {
        ...initialState.isUpdateInformationSuccess,
        ...action.payload,
      } as IAsyncStatus;
    },
    setCancelStatus: (state, action) => {
      state.cancelStatus = action.payload as IAsyncStatus;
    },
    setIsPostOpenSuccess: (state, action) => {
      state.isPostOpenSuccess = action.payload as boolean;
    },
    setDeleteExpectationStatus: (state, action) => {
      state.deleteExpectationStatus = {
        ...initialState.deleteExpectationStatus,
        ...action.payload,
      } as IAsyncStatus;
    },
    setCycleFeedbacks: (state, action) => {
      state.cycleFeedbacks = {
        ...initialState.cycleFeedbacks,
        ...action.payload,
      } as ICycleFeedbacks;
    },
    resetMyCycleSlice: state => {
      state.cycleInformation = {} as IInformation;
      state.expectationList = [] as IExpectationItem[];
      state.currentCycleId = NaN;
      state.currentCycleStatus = '';
      state.attachment = initialState.attachment;
      state.currentUpdatedExpectation = initialState.currentUpdatedExpectation;
      state.cycleDisplayPart = initialState.cycleDisplayPart;
    },
    setPastCycles: (state, action) => {
      state.pastCyclesStore.pastCycles = action.payload as IPastCycle[];
    },
    setPastYear: (state, action) => {
      state.pastYearStore.pastYear = action.payload as IPastYear;
    },
    setPastCyclesPageSelectedCycle: (state, action) => {
      state.pastCyclesPageStore.selectedCycle = action.payload as string;
    },
    setPastCyclesPageCycleFilterValue: (state, action) => {
      state.pastCyclesPageStore.cycleFilterValue = action.payload as string;
    },
    setPastCyclesPagesSelectedYear: (state, action) => {
      state.pastCyclesPageStore.selectedYear = action.payload as string;
    },
    setShowExpectationNonEngagedCycleTips: (state, action) => {
      state.overdueAndNonEngagedTip.showExpectationNonEngagedCycleTips = action.payload as boolean;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getOngoingCycleAsync.pending, state => {
        state.cycleDetailData = {
          loading: true,
          data: {} as ICycleInfo,
        } as ICycleDetailData;
      })
      .addCase(getOngoingCycleAsync.fulfilled, (state, action) => {
        const { cycleInformation, expectations } = action.payload;
        state.cycleDetailData = {
          loading: false,
          data: {
            ...omit(action.payload, 'cycleInformation'),
            ...cycleInformation,
          } as ICycleInfo,
        } as ICycleDetailData;
        // eslint-disable-next-line no-unused-expressions
        state.selfAssessmentAttachment;
        state.cycleInformation = cycleInformation;
        state.expectationList = expectations;
        if (!isEmpty(cycleInformation) && !isNil(cycleInformation)) {
          const {
            id,
            status,
            context: { content },
          } = cycleInformation;
          state.currentCycleId = id && +id;
          state.currentCycleStatus = status;
          state.cycleInformation.context.content = filterRichText(content);
        }
      })
      .addCase(getOngoingCycleAsync.rejected, state => {
        state.cycleDetailData = {
          loading: false,
          data: {} as ICycleInfo,
        } as ICycleDetailData;
      })
      .addCase(getCycleByCycleIdAsync.pending, state => {
        state.cycleDetailData = {
          loading: true,
          data: {} as ICycleInfo,
        } as ICycleDetailData;
      })
      .addCase(getCycleByCycleIdAsync.fulfilled, (state, action) => {
        const { cycleInformation, expectations } = action.payload;
        state.cycleDetailData = {
          loading: false,
          data: {
            ...omit(action.payload, 'cycleInformation'),
            ...cycleInformation,
          } as ICycleInfo,
        } as ICycleDetailData;

        state.cycleInformation = cycleInformation;
        state.expectationList = expectations;
        if (!isEmpty(cycleInformation) && !isNil(cycleInformation)) {
          const { id, status } = cycleInformation;
          state.currentCycleId = id && +id;
          state.currentCycleStatus = status;
        }
      })
      .addCase(getCycleByCycleIdAsync.rejected, state => {
        state.cycleDetailData = {
          loading: false,
          data: {} as ICycleInfo,
        } as ICycleDetailData;
      })
      .addCase(putUpdateExpectationAsync.pending, state => {
        state.isUpdatingExpectation = true;
        state.isUpdateInformationSuccess.success = false;
      })
      .addCase(putUpdateExpectationAsync.fulfilled, state => {
        state.currentUpdatedExpectation = null;
        state.isUpdateInformationSuccess.success = true;
        state.isUpdatingExpectation = false;
      })
      .addCase(putUpdateExpectationAsync.rejected, state => {
        state.isUpdatingExpectation = false;
        state.isUpdateInformationSuccess.success = false;
      })
      .addCase(deleteExpectationAsync.pending, state => {
        state.deleteExpectationStatus = {
          ...initialState.deleteExpectationStatus,
          loading: true,
        };
      })
      .addCase(deleteExpectationAsync.fulfilled, state => {
        state.currentUpdatedExpectation = null;
        state.deleteExpectationStatus = {
          ...initialState.deleteExpectationStatus,
          success: true,
        };
      })
      .addCase(deleteExpectationAsync.rejected, state => {
        state.deleteExpectationStatus = {
          ...initialState.deleteExpectationStatus,
          loading: false,
          success: true,
        };
      })
      .addCase(postUploadAttachmentAsync.pending, (state, action) => {
        const pendingState = { loading: true, success: false, reject: false };
        const { type } = action.meta.arg;
        switch (type) {
          case AttachmentType.CYCLE_INFORMATION:
            state.attachment = pendingState as IAttachment;
            break;
          case AttachmentType.SELF_ASSESSMENT:
            state.selfAssessmentAttachment = pendingState as IAttachment;
            break;
          case AttachmentType.V1_CYCLE_EXPECTATION:
            state.v1ExpectationAttachment = pendingState as IAttachment;
            break;
          default:
        }
      })
      .addCase(postUploadAttachmentAsync.fulfilled, (state, action) => {
        const { type, data } = action.payload;
        const fulfilledState = {
          ...data,
          loading: false,
          reject: false,
          success: true,
        };
        switch (type) {
          case AttachmentType.CYCLE_INFORMATION:
            state.attachment = fulfilledState as IAttachment;
            break;
          case AttachmentType.SELF_ASSESSMENT:
            state.selfAssessmentAttachment = fulfilledState as IAttachment;
            break;
          case AttachmentType.V1_CYCLE_EXPECTATION:
            state.v1ExpectationAttachment = fulfilledState as IAttachment;
            break;
          default:
        }
      })
      .addCase(postUploadAttachmentAsync.rejected, (state, action) => {
        const rejectedState = {
          loading: false,
          success: false,
          reject: true,
        };
        const { type } = action.meta.arg;
        switch (type) {
          case AttachmentType.CYCLE_INFORMATION:
            state.attachment = rejectedState as IAttachment;
            break;
          case AttachmentType.SELF_ASSESSMENT:
            state.selfAssessmentAttachment = rejectedState as IAttachment;
            break;
          case AttachmentType.V1_CYCLE_EXPECTATION:
            state.v1ExpectationAttachment = rejectedState as IAttachment;
            break;
          default:
        }
      })
      .addCase(getAttachmentsUrlAsync.pending, state => {
        state.attachment = {
          loading: true,
          success: false,
        } as IAttachment;
      })
      .addCase(getAttachmentsUrlAsync.fulfilled, (state, action) => {
        const { attachmentUrl = '' } = action.payload;
        state.attachment = {
          attachmentUrl: attachmentUrl,
          loading: false,
          success: true,
        } as IAttachment;
      })
      .addCase(getAttachmentsUrlAsync.rejected, state => {
        state.attachment = {
          loading: false,
          success: false,
        } as IAttachment;
      })
      .addCase(postAddInformationAsync.pending, state => {
        state.isUpdateInformationSuccess.success = false;
        state.isUpdateInformationSuccess.loading = true;
      })
      .addCase(postAddInformationAsync.fulfilled, state => {
        state.isUpdateInformationSuccess.success = true;
        state.isUpdateInformationSuccess.loading = false;
      })
      .addCase(postAddInformationAsync.rejected, state => {
        state.isUpdateInformationSuccess.success = false;
        state.isUpdateInformationSuccess.loading = false;
      })
      .addCase(putUpdateInformationAsync.pending, state => {
        state.isUpdateInformationSuccess.success = false;
        state.isUpdateInformationSuccess.loading = true;
      })
      .addCase(putUpdateInformationAsync.fulfilled, state => {
        state.isUpdateInformationSuccess.success = true;
        state.isUpdateInformationSuccess.loading = false;
      })
      .addCase(putUpdateInformationAsync.rejected, state => {
        state.isUpdateInformationSuccess.success = false;
        state.isUpdateInformationSuccess.loading = false;
      })
      .addCase(postAddExpectationAsync.pending, state => {
        state.isUpdatingExpectation = true;
        state.isUpdateInformationSuccess.success = false;
      })
      .addCase(postAddExpectationAsync.fulfilled, state => {
        state.isUpdatingExpectation = false;
        state.isUpdateInformationSuccess.success = true;
      })
      .addCase(postAddExpectationAsync.rejected, state => {
        state.isUpdatingExpectation = false;
        state.isUpdateInformationSuccess.success = false;
      })
      .addCase(postCancelCycleAsync.pending, state => {
        state.cancelStatus.loading = true;
        state.cancelStatus.success = false;
      })
      .addCase(postCancelCycleAsync.fulfilled, state => {
        state.cancelStatus.loading = false;
        state.cancelStatus.success = true;
      })
      .addCase(postOpenNormalCycleAsync.fulfilled, state => {
        state.isPostOpenSuccess = true;
      })
      .addCase(getCycleFeedbacksAsync.pending, state => {
        state.cycleFeedbacks = {
          ...initialState.cycleFeedbacks,
          loading: true,
        };
      })
      .addCase(getCycleFeedbacksAsync.fulfilled, (state, action) => {
        state.cycleFeedbacks = {
          ...initialState.cycleFeedbacks,
          list: action.payload,
          loading: false,
          success: true,
        };
      })
      .addCase(getCycleFeedbacksAsync.rejected, state => {
        state.cycleFeedbacks = {
          ...initialState.cycleFeedbacks,
          loading: false,
          success: false,
        };
      })
      .addCase(getPastCyclesByYearAsync.pending, state => {
        state.pastCyclesStore.pastCycles = initialState.pastCyclesStore.pastCycles;
        state.pastCyclesStore.getPastCyclesLoading = true;
        state.pastCyclesStore.getPastCyclesSuccess = false;
      })
      .addCase(getPastCyclesByYearAsync.fulfilled, (state, action) => {
        state.pastCyclesStore.pastCycles = action.payload;
        state.pastCyclesStore.getPastCyclesLoading = false;
        state.pastCyclesStore.getPastCyclesSuccess = true;
      })
      .addCase(getPastCyclesByYearAsync.rejected, state => {
        state.pastCyclesStore.pastCycles = initialState.pastCyclesStore.pastCycles;
        state.pastCyclesStore.getPastCyclesLoading = false;
        state.pastCyclesStore.getPastCyclesSuccess = false;
      })
      .addCase(getPastCycleAvailableYearAsync.pending, state => {
        state.pastYearStore.pastYear = initialState.pastYearStore.pastYear;
        state.pastYearStore.getPastYearLoading = true;
        state.pastYearStore.getPastYearSuccess = false;
      })
      .addCase(getPastCycleAvailableYearAsync.fulfilled, (state, action) => {
        state.pastYearStore.pastYear = action.payload;
        state.pastYearStore.getPastYearLoading = false;
        state.pastYearStore.getPastYearSuccess = true;
      })
      .addCase(getPastCycleAvailableYearAsync.rejected, state => {
        state.pastYearStore.pastYear = initialState.pastYearStore.pastYear;
        state.pastYearStore.getPastYearLoading = false;
        // eslint-disable-next-line max-lines
        state.pastYearStore.getPastYearSuccess = false;
      })
      .addCase(getOverdueAndNonEngagedTipAsync.pending, state => {
        state.overdueAndNonEngagedTip = initialState.overdueAndNonEngagedTip;
      })
      .addCase(getOverdueAndNonEngagedTipAsync.fulfilled, (state, action) => {
        state.overdueAndNonEngagedTip = {
          ...initialState.overdueAndNonEngagedTip,
          ...action.payload,
        };
      })
      .addCase(getOverdueAndNonEngagedTipAsync.rejected, state => {
        state.overdueAndNonEngagedTip = initialState.overdueAndNonEngagedTip;
      })
      .addCase(postAssessmentNonEngagedTipAsync.pending, state => {
        state.updateAssessmentNonEngagedTip = {
          ...initialState.updateAssessmentNonEngagedTip,
          loading: true,
        };
      })
      .addCase(postAssessmentNonEngagedTipAsync.fulfilled, state => {
        state.updateAssessmentNonEngagedTip = {
          ...initialState.updateAssessmentNonEngagedTip,
          success: true,
        };
      });
  },
});
export const myCycles = (state: IState) => state.myCycles;
export const cycleDisplayPart = (state: IState) => state.myCycles.cycleDisplayPart;
export const cycleVersion = (state: IState) => state.myCycles.cycleVersion;
export const expectationList = (state: IState) => state.myCycles.expectationList;
export const isOperationCycle = (state: IState) => state.myCycles.isOperationCycle;
export const cycleInformation = (state: IState) => state.myCycles.cycleInformation;
export const currentCycleId = (state: IState) => state.myCycles.currentCycleId;
export const currentCycleStatus = (state: IState) => state.myCycles.currentCycleStatus;
export const attachment = (state: IState) => state.myCycles.attachment;
export const selfAssessmentAttachment = (state: IState) => state.myCycles.selfAssessmentAttachment;
export const v1ExpectationAttachment = (state: IState) => state.myCycles.v1ExpectationAttachment;
export const cycleInformationFormData = (state: IState) => state.myCycles.cycleInformationFormData;
export const helperModalOptions = (state: IState) => state.myCycles.helperModalOptions;
export const cycleDetailData = (state: IState) => state.myCycles.cycleDetailData;
export const cycleFeedbacks = (state: IState) => state.myCycles.cycleFeedbacks;

export const currentUpdatedExpectation = (state: IState) =>
  state.myCycles.currentUpdatedExpectation;
export const lastExpectationCategoryIdsAfterEditing = (state: IState) =>
  state.myCycles.lastExpectationCategoryIdsAfterEditing;
export const isUpdateInformationSuccess = (state: IState) =>
  state.myCycles.isUpdateInformationSuccess;
export const isUpdatingExpectation = (state: IState) => state.myCycles.isUpdatingExpectation;
export const isPostOpenSuccess = (state: IState) => state.myCycles.isPostOpenSuccess;
export const deleteExpectationStatus = (state: IState) => state.myCycles.deleteExpectationStatus;
export const pastCycleStore = (state: IState) => state.myCycles.pastCyclesStore;
export const pastYearStore = (state: IState) => state.myCycles.pastYearStore;
export const pastCyclesPageStore = (state: IState) => state.myCycles.pastCyclesPageStore;
export const editDoneCycleChanged = (state: IState) => state.myCycles.editDoneCycleChanged;
export const overdueAndNonEngagedTip = (state: IState) => state.myCycles.overdueAndNonEngagedTip;
export const updateAssessmentNonEngagedTip = (state: IState) =>
  state.myCycles.updateAssessmentNonEngagedTip;

export const {
  setCycleDisplayPart,
  setCycleVersion,
  setCycleInformation,
  setExpectationList,
  setIsOperationCycle,
  setCurrentCycleId,
  setCurrentCycleStatus,
  setSelfAssessmentAttachment,
  setV1ExpectationAttachment,
  setCycleInformationFormData,
  setAttachment,
  setHelperModalOptions,
  setCycleDetailData,
  setCurrentUpdatedExpectation,
  setLastExpectationCategoryIdsAfterEditing,
  setIsUpdateInformationSuccess,
  setCancelStatus,
  setIsPostOpenSuccess,
  setDeleteExpectationStatus,
  resetMyCycleSlice,
  setPastCycles,
  setPastYear,
  setPastCyclesPagesSelectedYear,
  setPastCyclesPageSelectedCycle,
  setPastCyclesPageCycleFilterValue,
  setCycleFeedbacks,
  setEditDoneCycleChanged,
  setShowExpectationNonEngagedCycleTips,
} = myCyclesSlice.actions;
export default myCyclesSlice.reducer;
