import { IV2Project } from '@@/_new_src_/types/common';
import { IProject } from '@@/_new_src_/store/commonSlice/interface';
import {
  ASSESSMENT_NON_ENGAGED_DEADLINE,
  OVERDUE_DEADLINE,
  REQUEST_TO_EDIT_DEADLINE,
} from '@@/_new_src_/constants/common';
import { getThDay } from '@@/_new_src_/utils';
import { FEEDBACK_STATUS } from '@@/_new_src_/constants/feedback';
import { formatRangeDuration } from '@@/_new_src_/utils/common/date';
import { IDuration } from '@@/_new_src_/store/userSlice/interface';
import dayjs from 'dayjs';

const isH1StageForCycle = (cycleDurationEndTime: string) => {
  const June = 6;
  const getCurrentMonth = dayjs.utc(new Date(cycleDurationEndTime)).month() + 1;
  return getCurrentMonth === June;
};

const isH1StageForLocal = () => {
  const January = 1;
  const August = 8;
  const getCurrentMonth = dayjs.utc(new Date()).month() + 1;
  return getCurrentMonth >= January && getCurrentMonth < August;
};

export const formatV2Project = (project: IProject) => {
  const { accountId = '', assignmentId = '', projectName = '' } = project;
  return {
    account: accountId,
    assignmentId: assignmentId,
    name: projectName,
  };
};

export const formatV2ProjectListData = (projectList: IProject[]) => {
  return projectList.map(project => formatV2Project(project));
};

export const getV1ProjectParams = (project: IV2Project) => {
  const { account = '', assignmentId = '', name = '' } = project;
  return {
    projectAccount: account,
    projectId: assignmentId,
    projectName: name,
  };
};

export const getRequestToEditTips = (
  isFirstHalfOfYear: boolean,
  p1: string,
  p2: string,
  p3?: string,
) => {
  const deadline = isFirstHalfOfYear ? REQUEST_TO_EDIT_DEADLINE.H1 : REQUEST_TO_EDIT_DEADLINE.H2;

  let overdueText = p1 + deadline + p2;

  if (p3) {
    overdueText += deadline + p3;
  }

  return overdueText;
};

export const getExpectationNonEngagedTips = (
  expectationNonEngagedAt: string,
  p1: string,
  p2?: string,
) => {
  let expectationNonEngagedText = p1;

  const date = new Date(expectationNonEngagedAt);
  const day = getThDay(date.getDate());
  const month = date.toLocaleDateString('en-US', { month: 'long' });

  expectationNonEngagedText += `${month} ${day}`;
  expectationNonEngagedText += p2;

  return expectationNonEngagedText;
};

export const getFeedbackStatus = (status: string) => {
  const { PENDING, DRAFTED, SUBMITTED, NOT_PROVIDED, CANCELLED } = FEEDBACK_STATUS;
  return {
    isDraftedFeedback: status === DRAFTED,
    isSubmittedFeedback: status === SUBMITTED,
    isPendingFeedback: status === PENDING,
    isCancelledFeedback: status === CANCELLED,
    isNotProvidedFeedback: status === NOT_PROVIDED,
  };
};

export const getOverdueDate = () => {
  if (isH1StageForLocal()) {
    return OVERDUE_DEADLINE.H1;
  } else {
    return OVERDUE_DEADLINE.H2;
  }
};

export const getAssessmentNonEngagedDate = (duration: IDuration) => {
  const { startTime = '', endTime = '' } = duration;
  const assessmentNonEngagedDuration = formatRangeDuration(startTime, endTime);

  if (isH1StageForCycle(endTime)) {
    return {
      stage: ASSESSMENT_NON_ENGAGED_DEADLINE.H1,
      duration: assessmentNonEngagedDuration,
    };
  } else {
    return {
      stage: ASSESSMENT_NON_ENGAGED_DEADLINE.H2,
      duration: assessmentNonEngagedDuration,
    };
  }
};
