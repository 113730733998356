export enum ERROR_PAGE {
  IS_403 = '/performance/403',
  IS_404 = '/performance/404',
}

export enum MENU_PAGE_LINK {
  INDEX_PAGE = '/performance',
  LANDING_PAGE = '/performance/index',
  DASHBOARD_PAGE_PATH = '/performance/v2/dashboard',
  NON_ENGAGED_CYCLES_PAGE = '/performance/v2/nonEngagedCycles',
  CALIBRATE_INDICATORS_PAGE = '/performance/calibrate',
  TWERS_I_SUPPORT_PAGE = '/performance/twerisupport',
  FEEDBACK_MANAGEMENT_PAGE = '/performance/feedback',
  ADMIN_PAGE = '/performance/v2/admin',
}

export enum DASHBOARD_PAGE_LINK {
  CYCLES_PAGE = '/performance/v2/dashboard/cycles',
  REQUESTS_PAGE = '/performance/v2/dashboard/requests',
}

export enum CYCLE_PAGE_LINK {
  MY_CYCLES = '/performance/cycles',
  NEW_CYCLES_FORM = '/performance/myCycles',
  CYCLE_HISTORY_DETAIL = '/performance/cycles/cycleHistoryDetail',
  OLD_CYCLES_FORM = '/performance/cycles/setCycle',
}

export enum FEEDBACK_PAGE_LINK {
  INDEX = '/performance/feedback',
  DETAIL = '/performance/feedback/detail/',
  GIVEN = '/performance/feedback/given/',
  CLIENT = '/performance/feedback/client/',
  CREATION = '/performance/feedback/creation',
}

export enum DOCUMENT_LINK {
  FAQ_LINK = 'https://docs.google.com/document/d/1NC9YrDsSFBTcIi6qaBOdJz6Lngz4bCB3hoLFAdgP81E/edit#heading=h.nxkwvhz0feqb',
  TBP_LINK = 'https://docs.google.com/spreadsheets/d/17hGNpK_Reb8Id0TslzM2jWwpBlUIi_DTRzOtEcr8J4M/edit?usp=sharing',
  GROUP_MEMBER_TEMPLATE_FILE_LINK = 'https://docs.google.com/spreadsheets/d/1ZH_5Zdx-0T3i02aAp-JV6bIyL8Ld2rPH/edit#gid=928682458',
  PRIVACY_NOTICE_LINK = 'https://central.thoughtworks.net/home/global-employee-privacy-notice',
  SELF_ASSESSMENT_TEMPLATE_LINK = 'https://docs.google.com/spreadsheets/d/1g97PO26dHwWLn0DIXcpUkRLkYfA1uUdO/edit?usp=sharing&ouid=103567273113910290733&rtpof=true&sd=true',
  TBP_BULK_UPLOAD_TEMPLATE_LINK = 'https://docs.google.com/spreadsheets/d/1T5g9DKctM4efhLFML8U79oq5yyg3onoF/edit?usp=sharing&ouid=103567273113910290733&rtpof=true&sd=true',
  PLAYBOOK_LINK = 'https://docs.google.com/presentation/d/1NzvdEbE5UAJDor5g8cCSVv1nTi3B8ejR2v1O1ov_q5U/edit?pli=1#slide=id.g2cfee8e75da_25_1',
  ASSESSMENT_INPUT_PREPARATION_LINK = 'https://docs.google.com/document/d/1fkGMyLe34Vbquz57NOm99OUEIfRp05ml7NcZlvaThKw/edit?usp=sharing',
  PERFORMANCE_ASSESSMENT_PREPARATION_LINK = 'https://docs.google.com/document/d/1VIVg7NDePN6OqW8nCavQ4KDgGvAmWQ-aBTEv8GdAx70/edit',
}

export const PATHNAME_AND_PAGE_NAME = [
  { pageName: 'Home | Summit', pathName: MENU_PAGE_LINK.LANDING_PAGE },
  { pageName: 'Dashboard | Summit', pathName: DASHBOARD_PAGE_LINK.CYCLES_PAGE },
  { pageName: 'Non-engaged cycles | Summit', pathName: MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE },
  {
    pageName: 'Calibrate indicators cycles | Summit',
    pathName: MENU_PAGE_LINK.CALIBRATE_INDICATORS_PAGE,
  },
  { pageName: 'My cycles | Summit', pathName: CYCLE_PAGE_LINK.MY_CYCLES },
  { pageName: 'My cycles | Summit', pathName: CYCLE_PAGE_LINK.NEW_CYCLES_FORM },
  { pageName: 'Twers I support | Summit', pathName: MENU_PAGE_LINK.TWERS_I_SUPPORT_PAGE },
  { pageName: 'Feedback | Summit', pathName: MENU_PAGE_LINK.FEEDBACK_MANAGEMENT_PAGE },
  { pageName: 'Admin | Summit', pathName: MENU_PAGE_LINK.ADMIN_PAGE },
];

export const CURRENT_PAGE = (pathname: string) => {
  const isIndexPage = location.pathname === MENU_PAGE_LINK.INDEX_PAGE;
  const isLandingPage = pathname.includes(MENU_PAGE_LINK.LANDING_PAGE);
  const isCycleManagementPage =
    pathname.includes(MENU_PAGE_LINK.DASHBOARD_PAGE_PATH) ||
    pathname.includes(MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE) ||
    pathname.includes(MENU_PAGE_LINK.CALIBRATE_INDICATORS_PAGE);
  const isDashboardPage = pathname.includes(MENU_PAGE_LINK.DASHBOARD_PAGE_PATH);
  const isNonEngagedCyclesPage = pathname.includes(MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE);
  const isMyCyclesPage = pathname.includes(CYCLE_PAGE_LINK.MY_CYCLES);
  const isMyCyclesFormPage = pathname
    .toLowerCase()
    .includes(CYCLE_PAGE_LINK.NEW_CYCLES_FORM.toLowerCase());
  const isCalibrateIndicatorsPage = pathname.includes(MENU_PAGE_LINK.CALIBRATE_INDICATORS_PAGE);
  return {
    isIndexPage,
    isLandingPage,
    isCycleManagementPage,
    isDashboardPage,
    isNonEngagedCyclesPage,
    isMyCyclesPage,
    isMyCyclesFormPage,
    isCalibrateIndicatorsPage,
  };
};
