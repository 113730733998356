import React, { useCallback, useEffect } from 'react';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import { get, isInteger } from 'lodash';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { readRequestRejected, readUpdatedAssessment } from '@@/api/review';
import RequestRejectedTips from '@@/common/component/RequestRejectedTips';
import ReviewDetailPreview, {
  DEFAULT_OPERATION_CONFIG,
} from '@@/common/component/ReviewDetailPreview';
import useLocalStorageReview from '@@/hooks/useLocalStorageReview';
import {
  getCycleDetailByIdAsync,
  reviewState,
  setIsEditReviewHistoryDetail,
} from '@@/redux/slice/reviewSlice';
import './index.less';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import { CYCLE_VERSION } from '@@/_new_src_/constants/myCycles';
import { getCycleByCycleIdAsync } from '@@/_new_src_/store/myCyclesSlice/asyncThunk';
import { setCycleDetailData, setEditDoneCycleChanged } from '@@/_new_src_/store/myCyclesSlice';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { GET_CYCLE_DETAIL_API_PATH } from '@@/common/constant/review';
import CycleDetailByIdLayout from '@@/features/performance/reviews/cycleDetailByIdLayout';
import { MY_CYCLE_DETAIL_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { setIsSelfAssessmentChanged } from '@@/_new_src_/store/cycleDetailSlice';
import {
  setConfirmModalType,
  setConfirmModalStatus,
  confirmModalType,
} from '@@/_new_src_/store/commonSlice';
import { editDoneCycleChanged } from '@@/_new_src_/store/myCyclesSlice';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import OverdueNotification from '@@/_new_src_/page/myCycles/component/OverdueNotification';
import { userInfo } from '@@/_new_src_/store/userSlice';
import { CONFIRM_MODAL_TYPE_INFO } from '@@/_new_src_/constants/common';
import { CYCLE_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';

const ReviewHistoryDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const { id: cycleIdByPathname } = useParams();
  const location = useLocation();

  const {
    myCycles: {
      cycleModal: { cancelEdit: cancelEditLocale },
    },
  } = useSelector(selectLocaleResource);

  const { reviewHistory } = useSelector(selectLocaleResource);
  const { removeLocalReview } = useLocalStorageReview();
  const editDoneCycleChange = useSelector(editDoneCycleChanged);
  const currentModalType = useSelector(confirmModalType);

  const {
    cyclesCycleDetailV1: {
      data: { cycleVersion },
    },
  } = useSelector(reviewState);

  const {
    currentCycleDetail: {
      isDoneCycle,
      cycleDetailLoading,
      editRequestRejected,
      id: cycleId,
      project,
      assessmentUpdated,
      overdue,
    },
  } = useSelector(cycleDetailByVersion);

  const user = useSelector(userInfo);

  const getCurrentDetailFunc = useCallback(() => {
    if (cycleIdByPathname && isInteger(+cycleIdByPathname)) {
      if (cycleVersion === CYCLE_VERSION.NEW) {
        dispatch(getCycleByCycleIdAsync(+cycleIdByPathname));
      } else {
        dispatch(getCycleDetailByIdAsync(+cycleIdByPathname));
      }
    }
  }, [cycleVersion, cycleIdByPathname]);

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(setEditDoneCycleChanged(false));
  }, []);

  const goBackPage = () => {
    let backPage = CYCLE_PAGE_LINK.MY_CYCLES;
    dispatch(setEditDoneCycleChanged(false));
    dispatch(setCycleDetailData({}));
    dispatch(setIsSelfAssessmentChanged(false));
    history.push(backPage, {
      windowPosition: get(location, 'state.windowPosition'),
      selectedYear: get(location, 'state.selectedYear'),
      isFromReviewHistoryDetail: true,
    });
  };

  const onGoBack = useCallback(() => {
    if (editDoneCycleChange) {
      dispatch(setConfirmModalType(CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE));
      dispatch(setConfirmModalStatus(true));
    } else {
      goBackPage();
    }
  }, [history, location, editDoneCycleChange]);

  const handleConfirmCancelFunc = () => {
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalType(''));
    goBackPage();
  };

  const onSubmitReviewHistoryDetail = () => {
    dispatch(setIsEditReviewHistoryDetail(false));
    removeLocalReview();
    scrollToTop();
    getCurrentDetailFunc();
  };

  const onReadRequestRejected = useCallback(() => {
    trackEvent({
      category: MY_CYCLE_DETAIL_API.category,
      action:
        MY_CYCLE_DETAIL_API.action
          .TWER_READS_REJECT_TO_CYCLE_EDITING_REQUEST_IN_THE_CYCLE_DETAIL_PAGE,
    });
    readRequestRejected(cycleId).then();
  }, [cycleId]);

  const onReadUpdatedAssessment = useCallback(() => {
    readUpdatedAssessment(cycleId).then();
  }, [cycleId]);

  const onCancelEditReviewHistoryDetail = useCallback(() => {
    dispatch(setIsEditReviewHistoryDetail(false));
    removeLocalReview();
    scrollToTop();
  }, [dispatch, scrollToTop, removeLocalReview]);

  const renderExtra = useCallback(() => {
    return (
      <>
        {editRequestRejected && isDoneCycle && (
          <RequestRejectedTips
            message={reviewHistory.requestRejectedTips}
            onClose={onReadRequestRejected}
          />
        )}
        {assessmentUpdated && (
          <Alert
            className="assessment-updated-tips"
            type="info"
            message={reviewHistory.assessmentUpdatedTips}
            onClose={onReadUpdatedAssessment}
            showIcon
            closable
          />
        )}
      </>
    );
  }, [
    editRequestRejected,
    isDoneCycle,
    assessmentUpdated,
    onReadRequestRejected,
    onReadUpdatedAssessment,
    reviewHistory,
  ]);

  return (
    <CycleDetailByIdLayout v1ApiPath={GET_CYCLE_DETAIL_API_PATH.isReviewee}>
      <div className="review-history-detail">
        {!cycleDetailLoading && (
          <GoBackBanner
            title={reviewHistory.detailTitle + get(project, 'name', '')}
            goToBackFun={onGoBack}
            onKeyPress={onGoBack}
          />
        )}
        {user.hasOverdueCycle && overdue && <OverdueNotification />}
        <div className="detail-content">
          <LoadingWrapper loading={cycleDetailLoading}>
            <ReviewDetailPreview
              showFeedbackTabThoughNoSubmittedFeedback
              onCheckInNotesModalSuccess={getCurrentDetailFunc}
              defaultExpand
              onSendEditRequestSuccess={getCurrentDetailFunc}
              onRevieweeReviewEditCancel={onCancelEditReviewHistoryDetail}
              onRevieweeReviewEditSuccess={onSubmitReviewHistoryDetail}
              extra={renderExtra()}
              getCurrentReviewDetail={getCurrentDetailFunc}
              operation={{ ...DEFAULT_OPERATION_CONFIG }}
              isHistoryDetail
            />
          </LoadingWrapper>
        </div>
        {currentModalType === CONFIRM_MODAL_TYPE_INFO.CANCEL_EDIT_CYCLE && (
          <ConfirmModal {...cancelEditLocale} handleOkFunc={handleConfirmCancelFunc} />
        )}
      </div>
    </CycleDetailByIdLayout>
  );
};

export default ReviewHistoryDetail;
