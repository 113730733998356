import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPostCalibrateProps } from '@@/_new_src_/store/calibrateIndicatorsSlice/interface';
import {
  postAssessmentRecords,
  postCalibrateValidation,
} from '@@/_new_src_/api/calibrateIndicators';
import { get, isEmpty } from 'lodash';

export const postCalibrateValidationAsync = createAsyncThunk(
  'postCalibrateValidation',
  async (params: IPostCalibrateProps) => {
    const response = await postCalibrateValidation(params);
    return response.data;
  },
);

export const postAssessmentRecordsAsync = createAsyncThunk(
  'postAssessmentRecords',
  async (params: IPostCalibrateProps, { rejectWithValue }) => {
    try {
      const response = await postAssessmentRecords(params);
      return response.data;
    } catch (err) {
      const response = get(err, 'error.response', {});
      if (isEmpty(response)) {
        throw err;
      }
      return rejectWithValue(response);
    }
  },
);
