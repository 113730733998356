import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import './index.less';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';
import {
  getFeedbackDetailAsync,
  patchGivenFeedbackAsync,
} from '@@/_new_src_/store/V2FeedbackSlice/asyncThunk';
import { isEmpty, isEqual, isNil } from 'lodash';
import locale from '@@/_new_src_/local/feedback/en_US';
import {
  feedbackDetail,
  patchGivenFeedbackResponse,
  setActiveTabKey,
  setFeedbackDetail,
  setPatchGivenFeedbackResponse,
} from '@@/_new_src_/store/V2FeedbackSlice';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import {
  dateWithoutSeconds,
  dateWithYearMonth,
  dateWithYearMonthDay,
} from '@@/_new_src_/constants/dateFormatter';
import perfMessage from '@@/_new_src_/components/perfMessage';
import ElasticCollapse from '@@/_new_src_/components/ElasticCollapse';
import {
  FEEDBACK_ANSWER_MAX_LENGTH,
  FEEDBACK_REMIND_DATE,
  FEEDBACK_TAB_KEY,
} from '@@/_new_src_/constants/feedback';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import { setConfirmModalStatus } from '@@/_new_src_/store/commonSlice';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import cls from 'classnames';
import GoBackBanner from '@@/_new_src_/features/GoBackBanner';
import ExpectationItem from '@@/_new_src_/features/ExpectationItem';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { GIVEN_FEEDBACK_MANAGEMENT } from '@@/common/constant/matomo';
import { SUBMIT_FEEDBACK } from '@@/common/constant/qualaroo';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { getCycleStatus } from '@@/_new_src_/utils/feature/cycle';
import { FEEDBACK_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { isProdEnv, onEnter } from '@@/_new_src_/utils';
import { getFeedbackStatus } from '@@/_new_src_/utils/feature';

interface IFormValues {
  values: { [key: string]: string } | ArrayLike<string>;
  isDraft: boolean;
}

let currentAutoDraftTimerId: NodeJS.Timeout | null = null;

const GivenFeedbackForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const [form] = Form.useForm();
  const params = useParams();

  const {
    feedback: {
      noInputFromRequester: noInputFromRequesterLocale,
      giveFeedbackForm: {
        feedbackForTwer,
        project,
        cycleDuration,
        dueDate,
        cancelText,
        sendText,
        saveAsDraft,
        backHint,
        contextTitle,
        hideExpectation,
        showExpectation,
        dueInDays,
        autoSaveAsDraftSuccessMessage,
        saveSuccessMessage,
        submitSuccessMessage,
      },
      requestForm: { confirmModalInfo },
      giveFormExpectation: { expectationTitle, describe },
    },
  } = locale;

  const { id: feedbackId } = params as { id: number };

  const {
    loading: feedbackDetailLoading,
    formatContext,
    cycle: {
      projectName,
      duration: { startTime, endTime },
      cycleStatus,
    },
    feedback: { questions, requestName, dueDate: feedbackDueDate, overDueDays, feedbackStatus },
    selectedExpectations: selectedExpectations,
  } = useSelector(feedbackDetail);
  const { isOpenCycle, isDraftCycle } = getCycleStatus(cycleStatus);
  const { isPendingFeedback, isDraftedFeedback, isSubmittedFeedback } =
    getFeedbackStatus(feedbackStatus);
  const { success, loading } = useSelector(patchGivenFeedbackResponse);

  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [expectationVisible, setExpectationVisible] = useState(false);
  const [isAutoSaveAsDraft, setIsAutoSaveAsDraft] = useState(false);
  const [currentInitContents, setCurrentInitContents] = useState<string[]>([]);
  const [isDraftedFeedbackLocal, setIsDraftedFeedbackLocal] = useState<boolean>(false);
  const [isDraft, setIsDraft] = useState(false);

  const autoSaveInterval = isProdEnv() ? 1000 * 60 * 2 : 1000 * 15;

  useEffect(() => {
    setCurrentInitContents(() => questions.map(item => item.content));
  }, [questions]);

  useEffect(() => {
    !isEmpty(feedbackId) && dispatch(getFeedbackDetailAsync(feedbackId));
  }, [feedbackId]);

  useEffect(() => {
    if (isSubmittedFeedback && !isOpenCycle && !isDraftCycle) {
      history.push(`${FEEDBACK_PAGE_LINK.DETAIL}${feedbackId}`);
    }
    setIsDraftedFeedbackLocal(isDraftedFeedback);
  }, [isSubmittedFeedback, isOpenCycle, isDraftCycle]);

  useEffect(() => {
    !isEmpty(selectedExpectations) && setExpectationVisible(true);
  }, [selectedExpectations]);

  const isPendingOrDraftedFeedback = () => {
    return isPendingFeedback || isDraftedFeedbackLocal;
  };

  const backToFeedbackList = () => {
    history.push(FEEDBACK_PAGE_LINK.INDEX);
    dispatch(setActiveTabKey(FEEDBACK_TAB_KEY.GIVE_FEEDBACK));
  };

  const isAllFieldsEmpty = () => {
    const currentFormContent = Object.values(form.getFieldsValue());
    return currentFormContent.every(value => {
      const response = value as string;
      return isEmpty(response.trim());
    });
  };

  const confirmModalProps = () => {
    return {
      title: confirmModalInfo.title,
      content: confirmModalInfo.content,
      okText: confirmModalInfo.okText,
      cancelText: confirmModalInfo.cancelText,
      handleOkFunc: () => {
        backToFeedbackList();
        dispatch(setConfirmModalStatus(false));
      },
      handleCancelFunc: () => {
        dispatch(setConfirmModalStatus(false));
      },
    };
  };

  useEffect(() => {
    if (isDraftedFeedbackLocal && !isAllFieldsEmpty) {
      setSendButtonDisabled(false);
    }
  }, [feedbackStatus]);

  const handleFieldsChange = () => {
    const currentFormContent = Object.values(form.getFieldsValue());
    setSendButtonDisabled(isEqual(currentFormContent, currentInitContents) || isAllFieldsEmpty);
  };

  const scrollToBottom = (id: number) => {
    const textarea = document.getElementById(`${id}`) as HTMLElement;
    if (textarea) {
      textarea.scrollTop = textarea.scrollHeight;
    }
  };

  const handleCancel = () => {
    const currentFormContent = Object.values(form.getFieldsValue());
    if (!isEqual(currentFormContent, currentInitContents)) {
      dispatch(setConfirmModalStatus(true));
    } else {
      backToFeedbackList();
    }
  };

  const resetAutoDraftTimer = () => {
    if (currentAutoDraftTimerId) {
      clearTimeout(currentAutoDraftTimerId);
      currentAutoDraftTimerId = null;
    }
  };

  const handleSendOrDraft = ({ values, isDraft }: IFormValues) => {
    setIsDraft(isDraft);
    resetAutoDraftTimer();
    if (isDraft) {
      if (sendButtonDisabled) {
        return;
      }
      setSendButtonDisabled(true);
    }
    const currentValues = isDraft ? (form.getFieldsValue() as IFormValues['values']) : values;

    setCurrentInitContents(Object.values(currentValues));

    const feedbackQuestionDTO = Object.entries(currentValues).map(([questionId, content]) => ({
      questionId: Number(questionId),
      content: String(content).trim(),
      htmlTagQuestion: questions.find(item => item.id === Number(questionId))?.htmlTagQuestion,
    }));

    const feedbackExpectationDTO = selectedExpectations.map(ex => {
      return { expectationId: ex.id, updatedAt: ex.updatedAt, categoryIds: ex.categoryIds };
    });

    if (feedbackId) {
      dispatch(
        patchGivenFeedbackAsync({
          id: feedbackId,
          params: { isDraft, feedbackQuestionDTO, feedbackExpectationDTO },
        }),
      );
      trigSurvey(SUBMIT_FEEDBACK);
      trackEvent({
        category: GIVEN_FEEDBACK_MANAGEMENT.category,
        action: isDraft
          ? GIVEN_FEEDBACK_MANAGEMENT.action.DRAFT_FEEDBACK
          : GIVEN_FEEDBACK_MANAGEMENT.action.SEND_FEEDBACK,
      });
    }
  };

  const startAutoDraftTimer = () => {
    currentAutoDraftTimerId = setTimeout(() => {
      setIsAutoSaveAsDraft(true);
      handleSendOrDraft({ values: {}, isDraft: true });
    }, autoSaveInterval);
  };

  useEffect(() => {
    if (!sendButtonDisabled && isPendingOrDraftedFeedback()) {
      startAutoDraftTimer();
    } else {
      resetAutoDraftTimer();
    }
  }, [sendButtonDisabled]);

  useEffect(() => {
    return () => {
      dispatch(setFeedbackDetail({}));
      resetAutoDraftTimer();
    };
  }, []);

  useEffect(() => {
    if (success) {
      if (isAutoSaveAsDraft) {
        setIsAutoSaveAsDraft(false);
        perfMessage.success(autoSaveAsDraftSuccessMessage());
        setIsDraftedFeedbackLocal(true);
      } else {
        if (isDraft) {
          perfMessage.success(saveSuccessMessage());
        } else {
          perfMessage.success(
            submitSuccessMessage(dayjs.utc(new Date()).local().format(dateWithoutSeconds)),
          );
        }
        setIsDraft(false);
        backToFeedbackList();
      }
    }
    dispatch(setPatchGivenFeedbackResponse({}));
  }, [success, isAutoSaveAsDraft, isDraft]);

  const scrollIntoView = (id: string) => {
    setTimeout(() => {
      const elementAfter = document.getElementById(id);
      const clickedElementRectAfter = elementAfter?.getBoundingClientRect();
      const container = document.getElementById('scrolling-area');
      const containerRect = container?.getBoundingClientRect();
      if (
        !isNil(clickedElementRectAfter) &&
        !isNil(containerRect) &&
        clickedElementRectAfter?.bottom > containerRect?.bottom
      ) {
        const scrollToY = clickedElementRectAfter?.height;
        container?.scrollBy({
          top: (scrollToY as number) / 2,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, 300);
  };

  const handleExpectationChange = () => {
    setExpectationVisible(prevState => !prevState);
  };

  return (
    <LoadingWrapper loading={feedbackDetailLoading}>
      <div className="given-feedback-wrapper">
        <GoBackBanner title={backHint} goToBackFun={handleCancel} onKeyPress={handleCancel} />
        <div className="given-feedback-body">
          <div className="given-title">
            <span>{feedbackForTwer(requestName)}</span>
          </div>
          <div className="project-info">
            <span>
              <b>{project}</b> {projectName} | <b>{cycleDuration}</b>{' '}
              {dayjs.utc(startTime).local().format(dateWithYearMonth)} -{' '}
              {dayjs.utc(endTime).local().format(dateWithYearMonth)}
            </span>
            <span className="due-date-area">
              <p>
                <b>{dueDate}</b> {dayjs.utc(feedbackDueDate).local().format(dateWithYearMonthDay)}
              </p>
              {overDueDays <= FEEDBACK_REMIND_DATE && (
                <p className="days">
                  <i className="ri-information-fill" />
                  {dueInDays(overDueDays)}
                </p>
              )}
            </span>
          </div>
          <div className="give-wrapper">
            <div className="given-form">
              <div className="given-feedback-context">
                <div className="give-context-header">
                  <span>{contextTitle}</span>
                  {!isEmpty(selectedExpectations) ? (
                    <span
                      className="hide-expectation-button"
                      tabIndex={0}
                      onKeyDown={e => onEnter(e, () => handleExpectationChange())}
                      onClick={handleExpectationChange}
                    >
                      {expectationVisible ? hideExpectation : showExpectation}
                      {expectationVisible ? (
                        <i className="ri-eye-off-line hide-eye" />
                      ) : (
                        <i className="ri-eye-line show-eye" />
                      )}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
                <ElasticCollapse
                  maxHeight="100"
                  content={
                    formatContext ? (
                      <div dangerouslySetInnerHTML={{ __html: formatContext }} />
                    ) : (
                      <i>{noInputFromRequesterLocale}</i>
                    )
                  }
                />
              </div>
              <Form
                className={'given-feedback-form'}
                layout={'vertical'}
                onFinish={(values: IFormValues['values']) => {
                  resetAutoDraftTimer();
                  handleSendOrDraft({ values, isDraft: false });
                }}
                onFieldsChange={handleFieldsChange}
                form={form}
              >
                {questions.map(item => (
                  <Form.Item
                    key={item.id}
                    name={item.id}
                    className={'question-item'}
                    initialValue={item.content}
                    label={
                      <div className="question-info">
                        <div
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.htmlTagQuestion),
                          }}
                        />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: item.description,
                          }}
                        />
                      </div>
                    }
                  >
                    <Input.TextArea
                      onChange={() => scrollToBottom(item.id)}
                      autoSize={{ minRows: 3, maxRows: 13 }}
                      maxLength={FEEDBACK_ANSWER_MAX_LENGTH}
                      showCount={true}
                    ></Input.TextArea>
                  </Form.Item>
                ))}
                <Form.Item className="button-block">
                  <div className="button-area">
                    <Button className={'cancel-btn'} onClick={handleCancel}>
                      <i className="ri-close-circle-fill "></i>
                      {cancelText}
                    </Button>
                    <Button
                      className={cls('send-btn', { 'disable-btn': sendButtonDisabled })}
                      disabled={sendButtonDisabled && !isDraftedFeedbackLocal}
                      loading={loading}
                      htmlType={'submit'}
                    >
                      <i className="ri-send-plane-fill"></i>
                      {sendText}
                    </Button>
                  </div>
                  {isPendingOrDraftedFeedback() && (
                    <span
                      tabIndex={0}
                      onKeyDown={e =>
                        onEnter(e, () => handleSendOrDraft({ values: {}, isDraft: true }))
                      }
                      className={cls('draft-area', { 'disable-draft': sendButtonDisabled })}
                      onClick={() => {
                        resetAutoDraftTimer();
                        handleSendOrDraft({ values: {}, isDraft: true });
                      }}
                    >
                      {saveAsDraft}
                    </span>
                  )}
                </Form.Item>
              </Form>
            </div>
            <div
              className={cls('expectation-wrapper', { 'hide-expectation': !expectationVisible })}
            >
              <div className="expectation-icon">
                <div className="inner-expectation-icon"></div>
              </div>
              <div className="expectation-area">
                <div className="info-area">
                  <div className="title">{expectationTitle}</div>
                  <div className="content">
                    <span className="description">{describe}</span>
                  </div>
                </div>
                <div className="scrolling-area" id="scrolling-area">
                  {selectedExpectations?.map((item, index) => (
                    <div id={item.id.toString()} onClick={() => scrollIntoView(item.id.toString())}>
                      <ExpectationItem
                        key={index}
                        title={item.title}
                        content={item.content}
                        categoryIds={item.categoryIds}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal {...confirmModalProps()} />
      </div>
    </LoadingWrapper>
  );
};

export default GivenFeedbackForm;
